import React, { Component } from 'react';
import Moment from 'moment';
import 'moment/locale/es';
import Firebase from 'firebase';
import 'firebase/functions';
import HelperBusiness from '../../helper/Business';
import HelperPage from '../../helper/Page';
import Navigation from '../../components/Navigation';
import Header from '../../components/Headers/header';
import ListEmpty from '../../components/List/empty';
import * as ROUTES from '../../constants/routes';
import { withRouter } from 'react-router-dom';
import EventEmitter from '../../helper/Emitter';
import { withTranslation } from "react-i18next";
import lang from 'i18next';
import { AuthUserContext, withAuthorization } from '../../components/Session';



class RosterPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      clients: global.clients,
      sessions: global.sessions,
      sessionsList: [],
      trainers: global.userStaff,
      trainer: 'all',
      currentMonth: 0,
      selected: [],
      colors: ['darkblue', 'green', 'orange', 'purple', 'red', 'yellow', 'pink', 'green2', 'blue2', 'darkblue', 'green', 'orange', 'purple', 'red', 'yellow', 'pink', 'green2', 'blue2', 'darkblue', 'green', 'orange', 'purple', 'red', 'yellow', 'pink', 'green2', 'blue2'],
      images: [],
      assignee: '',
    };
  }


  componentDidMount() {
    HelperPage.initialize('Roster - PT Mate', ROUTES.ROSTER)
    HelperPage.checkMessage()
    this._isMounted = true

    EventEmitter.subscribe('userLoaded', (event) => this.configureData())
    EventEmitter.subscribe('clientsLoaded', (event) => this.configureData())
    EventEmitter.subscribe('sessionsLoaded', (event) => this.configureData())
    EventEmitter.subscribe('locationSet', (event) => this.configureData())
    this.configureData()
  }


  componentWillUnmount() {
    this._isMounted = false
  }


  configureData() {
    if(this._isMounted) {
      var tmp1 = []
      var tmp2 = []
      for(var item1 of global.sessions) {
        if(global.loc === '') {
          tmp1.push(item1)
        } else {
          if(item1.data.location === global.loc) {
            tmp1.push(item1)
          }
        }
      }
      for(var item2 of global.userStaff) {
        if(item2.data.role === 'assistant' || item2.data.role === 'assistant2' || item2.data.role === 'trainer') {
          if(global.loc === '') {
            tmp2.push(item2)
          } else {
            for(var loc of global.locations) {
              if(loc.id === global.loc) {
                if(loc.data.staff !== undefined) {
                  if(loc.data.staff.indexOf(item2.id) !== -1) {
                    tmp2.push(item2)
                  }
                }
              }
            }
          }
          
        }
      }
      this.setState({
        clients: global.clients,
        sessions: tmp1,
        trainers: tmp2,
      }, () => {
        this.configureSessions()
        var images = []
        for(var item of this.state.trainers) {
          this.storeImage(item, images)
        }
      })
    }
  }


  storeImage(item, images) {
    Firebase.storage().ref().child('/images/users/'+item.id+'.jpg').getDownloadURL().then((url) => {
      images.push({id: item.id, url: url})
      this.setState({
        images: images
      })
    }).catch((error) => {
      // Handle errors
    })
  }


  getImage(id, name) {
    var inits = ''
    let arr = name.split(' ')
    if(arr.length > 1) {
      inits = arr[0].charAt(0)+arr[1].charAt(0)
    } else {
      inits = arr[0].charAt(0)
    }
    var img = ''
    for(var item of this.state.images) {
      if(item.id === id) {
        img = item.url
      }
    }
    if(img === '') {
      return (
        <div className="avatar">
          <p>{inits}</p>
        </div>
      )
    } else {
      return (
        <div className="avatar" style={{backgroundImage: 'url('+img+')'}}> </div>
      )
    }
  }


  getMainImage() {
    var inits = ''
    let arr = global.userName.split(' ')
    if(arr.length > 1) {
      inits = arr[0].charAt(0)+arr[1].charAt(0)
    } else {
      inits = arr[0].charAt(0)
    }
    if(global.userImage === '') {
      return (
        <div className="avatar">
          <p>{inits}</p>
        </div>
      )
    } else {
      return (
        <div className="avatar" style={{backgroundImage: 'url('+global.userImage+')'}}> </div>
      )
    }
  }


  configureSessions() {
    var sessions = []
    for(var item of this.state.sessions) {
      if(item.data.trainerId === this.state.trainer || this.state.trainer === 'all') {
        sessions.push(item)
      }
    }
    this.setState({
      sessionsList: sessions
    })
  }


  getSessionClass(item) {
    var label = 'session unassigned'
    if(global.uidUser === item.data.trainerId) {
      label = 'session blue'
    }
    if(item.data.trainerId !== '' && item.data.trainerId !== undefined) {
      for(var i=0; i<this.state.trainers.length; i++) {
        if(this.state.trainers[i].id === item.data.trainerId) {
          label = 'session '+this.state.colors[i]
        }
      }
    }
    if(this.state.selected.indexOf(item.id) !== -1) {
      label += ' selected'
    }
    return label
  }


  getSessionsTrainer(id) {
    var v1 = 0
    var v2 = 0
    for(var item of this.state.sessions) {
      if(Moment(item.data.timestamp, 'X').format('MM/YYYY') === Moment().add(this.state.currentMonth, 'month').format('MM/YYYY')) {
        if(item.data.trainerId === id || id === 'all') {
          v1++
          v2 += item.data.duration
        }
      }
    }
    var v2a = (v2/60).toFixed(1)
    var array = [v1, v2a]
    return array
  }



  // Interactive stuff ------------------------------------------------------------



  selectTrainer(id) {
    this.setState({
      trainer: id,
      selected: [],
    }, () => {
      this.configureSessions()
    })
  }

  
  selectSession(id) {
    var tmp = this.state.selected
    if(tmp.indexOf(id) !== -1) {
      for(var i=0; i<tmp.length; i++) {
        if(tmp[i] === id) {
          tmp.splice(i, 1)
        }
      }
    } else {
      tmp.push(id)
    }
    this.setState({
      selected: tmp
    })
  }


  selectAssignee(event) {
    this.setState({
      assignee: event.target.value
    });
  }


  assignSessions() {
    var name = ''
    for(var tr of this.state.trainers) {
      if(tr.id === this.state.assignee) {
        name = tr.data.name
      }
    }
    if(this.state.assignee === global.uidUser) {
      name = global.userName
    }
    for(var item of this.state.selected) {
      Firebase.database().ref('/sessions/'+global.uid+'/'+item).update({
        trainerId: this.state.assignee,
        trainerName: name
      })
    }
    EventEmitter.dispatch('showMessage', lang.t('messaging:message.sessionsassigned'));
    setTimeout(() => {
      this.setState({
        selected: []
      })
    }, 50);
  }



  // Display stuff ------------------------------------------------------------



  getSessionName(item) {
    var label = lang.t('calendar:session.group')
    for(var session of this.state.sessions) {
      if(session.id === item.id && item.data.group && item.data.client !== '' && item.data.client !== undefined) {
        label = item.data.client 
      }
      if(session.id === item.id && !item.data.group && item.data.client !== undefined) {
        label = lang.t('calendar:session.11')
        for(var client of global.clients) {
          if(client.id === item.data.client) {
            label = client.data.name
          }
        }
      }
    }
    return label
  }


  getSessionCustomName(item) {
    if(item.data.name !== undefined && item.data.name !== '') {
      return <span>{item.data.name}<br/></span>
    }
  }


  renderDaySessions(date) {
    var list = []
    for(var sess of this.state.sessionsList) {
      if(Moment(sess.data.timestamp, 'X').format('D/MM/YYYY') === date) {
        list.push(sess)
      }
    }
    list.sort((a,b) => Moment(a.data.date, 'DD/MM/YYYY HH:mm') - Moment(b.data.date, 'DD/MM/YYYY HH:mm'));
    return (
      <div>
        {list.map((item) => (
          <div className={this.getSessionClass(item)} key={item.id} onClick={() => this.selectSession(item.id)}>
            <div className="team-bar"></div>
            <div className="text"><strong>{this.getSessionName(item)}</strong><br/>{this.getSessionCustomName(item)}{Moment(item.data.timestamp, 'X').locale(lang.language).format('LT')}</div>
          </div>
        ))}
      </div>
    )
  }


  renderMonth() {
    var list = []
    var end = parseInt(Moment().startOf('month').add(this.state.currentMonth+1, 'month').add(-1, 'days').format('D'))
    var base = Moment().add(this.state.currentMonth, 'month').format('/MM/YYYY')
    for(var i=0; i<end; i++) {
      list.push(i+1+base)
    }
    return (
      <div style={{width: list.length*140}}>
        {list.map((item, index) => (
          <div className="roster-day">
            <p className="head"><span>{Moment(item, 'D/MM/YYYY').locale(lang.language).format('ddd')}<br/></span>{index+1}</p>
            <div className="clear"></div>
            {this.renderDaySessions(item)}
          </div>
        ))}
        <div className="clear"></div>
      </div>
    )
  }


  renderStaff() {
    var list = this.state.trainers
    return (
      <div className="roster-staff mb-10">
        <div style={{width: (this.state.trainers.length*230+690)}}>
          <div className={this.state.trainer === 'all' ? 'teammember active' : 'teammember'} onClick={() => this.selectTrainer('all')}>
            <div className="team-bar"></div>
            <div className="icon primary">
              <div className="inner group"></div>
            </div>
            <div className="text"><strong>{lang.t('calendar:label.allstaff')}</strong><br/>{this.getSessionsTrainer('all')[0]} {this.getSessionsTrainer('all')[0] === 1 ? lang.t('calendar:roster.session') : lang.t('calendar:roster.sessions')}<br/>{this.getSessionsTrainer('all')[1]} {lang.t('calendar:label.hours')}</div>
          </div>
          <div className={this.state.trainer === global.uidUser ? 'teammember active' : 'teammember'} onClick={() => this.selectTrainer(global.uidUser)}>
            <div className="team-bar blue"></div>
            {this.getMainImage()}
            <div className="text"><strong>{lang.t('common:label.yourself')}</strong><br/>{this.getSessionsTrainer(global.uidUser)[0]} {this.getSessionsTrainer(global.uidUser)[0] === 1 ? lang.t('calendar:roster.session') : lang.t('calendar:roster.sessions')}<br/>{this.getSessionsTrainer(global.uidUser)[1]} {lang.t('calendar:label.hours')}</div>
          </div>
          {list.map((item, index) => (
            <div className={this.state.trainer === item.id ? 'teammember active' : 'teammember'} onClick={() => this.selectTrainer(item.id)}>
              <div className={'team-bar '+this.state.colors[index]}></div>
              {this.getImage(item.id, item.data.name)}
              <div className="text"><strong>{item.data.name} {item.data.status === 'active' ? '' : '(Inactive)'}</strong><br/>{this.getSessionsTrainer(item.id)[0]} {this.getSessionsTrainer(item.id)[0] === 1 ? lang.t('calendar:roster.session') : lang.t('calendar:roster.sessions')}<br/>{this.getSessionsTrainer(item.id)[1]} {lang.t('calendar:label.hours')}</div>
            </div>
          ))}
          <div className={this.state.trainer === '' ? 'teammember active' : 'teammember'} onClick={() => this.selectTrainer('')}>
            <div className="team-bar"></div>
            <div className="avatar"><p>?</p></div>
            <div className="text"><strong>{lang.t('calendar:label.unassigned')}</strong><br/>{this.getSessionsTrainer('')[0]} {this.getSessionsTrainer('all')[0] === 1 ? lang.t('calendar:roster.session') : lang.t('calendar:roster.sessions')}<br/>{this.getSessionsTrainer('')[1]} {lang.t('calendar:label.hours')}</div>
          </div>
        </div>
      </div>
    )
  }


  renderActions() {
    if(this.state.selected.length > 0) {
      var list = []
      for(var item of this.state.trainers) {
        if(item.data.status === 'active') {
          list.push(item)
        }
      }
      list.sort((a,b) => a.data.name.localeCompare(b.data.name))
      return (
        <div className="col-4 rgt" style={{height: 60}}>
          <label>{lang.t('calendar:label.assignsessionsto')}</label>
          <div className="col-7">
            <select onChange={event => this.selectAssignee(event)} value={this.state.assignee}>
              <option value={global.uidUser}>{lang.t('common:label.yourself')}</option>
              {list.map((item) => (
                <option value={item.id}>{item.data.name}</option>
              ))}
              <option value=''>{lang.t('calendar:label.unassigned')}</option>
            </select>
          </div>
          <div className="col-5">
            <button className="btn primary" onClick={() => this.assignSessions()}>{lang.t('calendar:button.assign')}</button>
          </div>
          <div className="clear"></div>
        </div>
      )
    } else {
      return <div className="col-4 rgt" style={{height: '60px'}}>&nbsp;</div>
    }
  }


  renderSubtitle() {
    if(this.state.selected.length === 0) {
      return (
        <h3 className="clear col-6 mb-20">{lang.t('calendar:roster.selectsessions')}</h3>
      )
    } else {
      return (
        <h3 className="clear col-6 mb-20">{this.state.selected.length} {this.state.selected.length === 1 ? lang.t('calendar:roster.sessionselected') : lang.t('calendar:roster.sessionsselected')} <button className="btn tertiary small close" style={{display: 'inline-block'}} onClick={() => this.setState({selected: []})}>{lang.t('common:button.clear')}</button></h3>
      )
    }
  }


  renderContent() {
    return (
      <div className={'theme-'+global.spaceTheme}>
        <div className="content withtabs">
          <div className="col-12 mt-20">
            {this.renderSubtitle()}
            <div className="col-2">&nbsp;</div>
            {this.renderActions()}
            <div className="clear sv-40"></div>
            <div className="radios clear">
              <button className={this.state.currentMonth === -1 ? 'radio col-3 active' : 'radio col-3'} onClick={() => this.setState({currentMonth: -1, selected: []})}>{Moment().add(-1, 'month').locale(lang.language).format('MMMM YYYY')}</button>
              <button className={this.state.currentMonth === 0 ? 'radio col-3 active' : 'radio col-3'} onClick={() => this.setState({currentMonth: 0, selected: []})}>{Moment().locale(lang.language).format('MMMM YYYY')}</button>
              <button className={this.state.currentMonth === 1 ? 'radio col-3 active' : 'radio col-3'}onClick={() => this.setState({currentMonth: 1, selected: []})}>{Moment().locale(lang.language).add(1, 'month').format('MMMM YYYY')}</button>
              <button className={this.state.currentMonth === 2 ? 'radio col-3 active' : 'radio col-3'} onClick={() => this.setState({currentMonth: 2, selected: []})}>{Moment().locale(lang.language).add(2, 'month').format('MMMM YYYY')}</button>
            </div>
            <div className="sv-10"></div>
            {this.renderStaff()}
            <div className="roster-days">
              {this.renderMonth()}
            </div>
          </div>
          <div className="sv-40"></div>
        </div>
        <Header type='calendar' active='roster'/>
        <Navigation active='calendar' />
      </div>
    )
  }


  renderBase() {
    if(global.showHabits) {
      if(HelperBusiness.getRoleIncl('trainer,admin')) {
        if(global.maxClients !== 0) {
          return (
            <div className={'theme-'+global.spaceTheme}>
              <div className="content form-container withtabs">
                <ListEmpty id='roster-locked2'/>
              </div>
              <Header type='calendar' active='roster'/>
              <Navigation active='calendar' />
            </div>
          )
        } else {
          return (
            this.renderContent()
          )
        }
      } else {
        return (
          <div className={'theme-'+global.spaceTheme}>
            <div className="content">
              <ListEmpty id='roster-locked' type='simple'/>
            </div>
            <Header type='simple' title={lang.t('nav:main.calendar')}/>
            <Navigation active='calendar' />
          </div>
        )
      }
    // No Business subscription
    } else {
      if(HelperBusiness.getRoleIncl('')) {
        return (
          <div className={'theme-'+global.spaceTheme}>
            <div className="content">
              <ListEmpty id='roster-locked2'/>
            </div>
            <Header type='calendar' active='roster'/>
            <Navigation active='calendar' />
          </div>
        )
      } else {
        return (
          <div className={'theme-'+global.spaceTheme}>
            <div className="content">
              <ListEmpty id='roster-locked' type='simple'/>
            </div>
            <Header type='simple' title={lang.t('nav:main.calendar')}/>
            <Navigation active='calendar' />
          </div>
        )
      }
      
    }
  }


  render() {
    return (
      <AuthUserContext.Consumer>
        {authUser => (
          this.renderBase()
        )}
      </AuthUserContext.Consumer>
    )
  }
}



const condition = authUser => !!authUser;
export default withTranslation(['calendar','common','nav','messaging'])(withRouter(withAuthorization(condition)(RosterPage)));