import React, { Component } from 'react';
import Moment from 'moment';
import Firebase from 'firebase';
import Connector from '../../data/Connector';
import HelperBusiness from '../../helper/Business';
import HelperClients from '../../helper/Clients';
import * as ROUTES from '../../constants/routes';
import { Link, withRouter } from 'react-router-dom';
import HelperPage from '../../helper/Page';
import EventEmitter from '../../helper/Emitter';
import Navigation from '../../components/Navigation';
import Header from '../../components/Headers/header';
import ListEmpty from '../../components/List/empty';
import WidgetChartGraph from '../../components/Widgets/chart-graph';
import { withTranslation } from "react-i18next";
import lang from 'i18next';
import { AuthUserContext, withAuthorization } from '../../components/Session';



class AdminPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      clients: global.clients,
      clientsIn: global.clients,
      trainer: global.userStaff,
      sessions: global.sessions,
      archive: global.archive,
      requests: [],
      requestsList: [],
      total: [],
      date1: Moment().add(-30, 'days').format('YYYY-MM-DD'),
      date2: Moment().format('YYYY-MM-DD'),
      inactive: [],
      payments: global.payments,
      paymentsArchive: global.paymentsArchive,
      products: global.products,
      dateRange: global.dateRange,
      dataTop: [
        {
          "id": "japan",
          "color": "#1DC5C9",
          "data": []
        },
      ],
      dataClients: [
        {
          "id": "japan",
          "color": "#1DC5C9",
          "data": []
        },
      ],
    };
  }


  componentDidMount() {
    HelperPage.initialize('Admin - PT Mate', ROUTES.ADMINASSISTANT)
    HelperPage.checkMessage()
    this._isMounted = true
    
    EventEmitter.subscribe('userLoaded', (event) => this.configureData())
    EventEmitter.subscribe('staffLoaded', (event) => this.configureData())
    EventEmitter.subscribe('trainersLoaded', (event) => this.configureData())
    EventEmitter.subscribe('clientsLoaded', (event) => this.configureData())
    EventEmitter.subscribe('sessionsLoaded', (event) => this.configureData())
    EventEmitter.subscribe('archiveLoaded', (event) => this.configureData())
    EventEmitter.subscribe('publicRequestsLoaded', (event) => this.configureData())
    EventEmitter.subscribe('locationSet', (event) => this.configureData())
    this.configureData()
  }


  componentWillUnmount() {
    this._isMounted = false
  }


  configureData() {
    if(this._isMounted) {
      var tmp = []
      var tmp2 = []
      for(var item of global.userLog) {
        if(item.data.type === 'assistantinvite' && item.data.message === 'invited') {
          tmp.push({
            id: item.id,
            name: item.data.title,
          })
        }
        if(item.data.type === 'assistant') {
          tmp2.push({
            id: item.id,
            name: item.data.title,
          })
        }
      }

      var tmp3 = []
      var ids = []

      var tmpc1 = []
      var tmpc2 = []
      var tmpc3 = []
      var tmpc4 = []
      var tmpc5 = []
      var tmpc6 = []

      if(global.paymentsArchive.length === 0 && global.uidUser !== '') {
        Connector.loadPaymentsArchive((result) => {})
      }
      if(global.archive.length === 0 && global.uidUser !== '') {
        Connector.loadArchive((result) => {})
      }

      var list = []
      var list2 = []
      if(global.loc !== '') {
        for(var loc of global.locations) {
          if(loc.id === global.loc && loc.data.clients !== undefined) {
            list = loc.data.clients
          }
          if(loc.id === global.loc && loc.data.staff !== undefined) {
            list2 = loc.data.staff
          }
        }
      } else {
        for(var cl of global.clients) {
          list.push(cl.id)
        }
        for(var st of global.userStaff) {
          list2.push(st.id)
        }
      }

      for(var item44 of global.clients) {
        if(list.indexOf(item44.id) !== -1) {
          tmpc1.push(item44)
            tmp3.push({
            id: item44.id,
            name: item44.data.name,
            income: 0,
            payments: 0,
            image: item44.data.image
          })
          ids.push(item44.id)
        }
      }

      for(var itemc2 of global.payments) {
        if(list.indexOf(itemc2.data.client) !== -1) {
          tmpc2.push(itemc2)
        }
      }
      for(var itemc3 of global.paymentsArchive) {
        if(list.indexOf(itemc3.data.client) !== -1) {
          tmpc3.push(itemc3)
        }
      }
      for(var itemc4 of global.userStaff) {
        if(list2.indexOf(itemc4.id) !== -1) {
          tmpc4.push(itemc4)
        }
      }
      for(var itemc5 of global.sessions) {
        if(global.loc === '' || itemc5.data.location === global.loc) {
          tmpc5.push(itemc5)
        }
      }
      for(var itemc6 of global.archive) {
        if(global.loc === '' || itemc6.data.location === global.loc) {
          tmpc6.push(itemc6)
        }
      }

      this.setState({
        clients: tmpc1,
        clientsIn: tmp3,
        payments: tmpc2,
        paymentsArchive: tmpc3,
        products: global.products,
        trainer: tmpc4,
        sessions: tmpc5,
        archive: tmpc6,
        pending: tmp,
        invites: tmp2,
        requests: global.publicRequests,
      }, () => {
        this.checkTrainers()
        this.getSessions()
        this.getTopGraph()
        this.getPayments()
        this.setRequests(this.state.requests)
      })
    }
  }


  // Get sessions per user

  getSessions() {
    var tmp = this.state.clients
    for(var tp of tmp) {
      tp.sessions = 0
      tp.group = 0
      tp.total = 0
    }

    for(var item of this.state.sessions) {
      var date = parseInt(Moment(item.data.date, 'DD/MM/YYYY').format('X'))
      if(date >= Moment(this.state.date1, 'YYYY-MM-DD').format('X') && date <= Moment(this.state.date2, 'YYYY-MM-DD').format('X')) {
        for(var client of tmp) {
          if(client.id === item.data.client) {
            client.sessions += 1
            client.total += 1
          }
          if(item.data.group && !item.data.availability && item.data.clients !== undefined) {
            if(item.data.clients.indexOf(client.id) !== -1) {
              client.group += 1
              client.total += 1
            }
          }
        }
      }
    }
    for(var item2 of this.state.archive) {
      var date2 = parseInt(Moment(item2.data.date, 'DD/MM/YYYY').format('X'))
      if(date2 >= Moment(this.state.date1, 'YYYY-MM-DD').format('X') && date2 <= Moment(this.state.date2, 'YYYY-MM-DD').format('X')) {
        for(var client2 of tmp) {
          if(client2.id === item2.data.client) {
            client2.sessions += 1
            client2.total += 1
          }
          if(item2.data.group && !item2.data.availability && item2.data.clients !== undefined) {
            if(item2.data.clients.indexOf(client2.id) !== -1) {
              client2.group += 1
              client2.total += 1
            }
          }
        }
      }
    }

    this.setState({
      clients: tmp
    })
  }


  // Trial requests

  setRequests(list) {
    var tmp = []
    var minDate = Moment().add(-31, 'days')
    for(var item of list) {
      if(Moment(item.data.sent, 'DD/MM/YYYY HH:mm') > minDate && Moment(item.data.sent, 'DD/MM/YYYY HH:mm') < Moment()) {
        tmp.push(item)
      }
    }
    this.setState({
      requestsList: tmp
    })
  }


  // Get payments per user

  getPayments() {
    var tmp = this.state.clientsIn
    for(var tp of tmp) {
      tp.income = 0
      tp.payments = 0
    }

    for(var item of this.state.payments) {
      var date = parseInt(Moment(item.data.date, 'DD/MM/YYYY').format('X'))
      if(date >= Moment(this.state.date1, 'YYYY-MM-DD').format('X') && date <= Moment(this.state.date2, 'YYYY-MM-DD').format('X')) {
        for(var client of tmp) {
          if(client.id === item.data.client) {
            client.income += item.data.amount/100
            client.payments += 1
          }
        }
      }
    }
    for(var item2 of this.state.paymentsArchive) {
      var date2 = parseInt(Moment(item2.data.date, 'DD/MM/YYYY').format('X'))
      if(date2 >= Moment(this.state.date1, 'YYYY-MM-DD').format('X') && date2 <= Moment(this.state.date2, 'YYYY-MM-DD').format('X')) {
        for(var client2 of tmp) {
          if(client2.id === item2.data.client) {
            client2.income += item2.data.amount/100
            client2.payments += 1
          }
        }
      }
    }

    this.setState({
      clientsIn: tmp
    })
  }


  checkTrainers() {
    for(var item of global.userLog) {
      if(item.data.type === 'assistantinvite' && item.data.message === 'invited') {
        for(var trainer of this.state.trainer) {
          if(trainer.id === item.id) {
            Firebase.database().ref('/log/'+global.uidUser+'/'+item.id).remove()
          }
        }
      }
    }
  }


  getTopGraph() {
    var tmp = []
    var tmpg = []
    var tmpp = []
    var tmpc = []
    var tmpc1 = []
    var tmpc2 = []
    var inactive = []
    var cls = []

    var start = Moment(this.state.date1, 'YYYY-MM-DD').startOf('week')
    var end = Moment(this.state.date2, 'YYYY-MM-DD').add(1, 'week').startOf('week')
    var now = parseInt(Moment().format('X'))
    var weeks = end.diff(start, 'weeks')

    for(var i=0; i<parseInt(weeks); i++) {
      var num = 0
      var numg = 0
      var nump = 0
      
      var numc = []
      var numc1 = []
      var numc2 = []

      for(var item of this.state.sessions) {
        var date = parseInt(Moment(item.data.date, 'DD/MM/YYYY').format('X'))
        if(date >= Moment(this.state.date1, 'YYYY-MM-DD').format('X') && date >= Moment(start).add(7*i, 'days').format('X') && date < Moment(start).add(7*i+7, 'days').format('X') && date < now) {
          num++
          if(item.data.group && !item.data.availability) {
            numg++
            //totalg++
            if(item.data.clients !== undefined) {
              for(var client of item.data.clients) {
                if(numc.indexOf(client) === -1) {
                  numc.push(client)
                  numc1.push(client)
                  cls.push(client)
                }
              }
            }
          } else {
            nump++
            //totalp++
            if(numc.indexOf(item.data.client) === -1) {
              numc.push(item.data.client)
              numc2.push(item.data.client)
              cls.push(item.data.client)
            }
          }
        }
      }

      for(var item2 of this.state.archive) {
        var date2 = parseInt(Moment(item2.data.date, 'DD/MM/YYYY').format('X'))
        if(date2 >= Moment(this.state.date1, 'YYYY-MM-DD').format('X') && date2 >= Moment(start).add(7*i, 'days').format('X') && date2 < Moment(start).add(7*i+7, 'days').format('X') && date2 < now) {
          num++
          if(item2.data.group && !item.data.availability) {
            numg++
            //totalg++
            if(item2.data.clients !== undefined) {
              for(var client2 of item2.data.clients) {
                if(numc.indexOf(client2) === -1) {
                  numc.push(item2.data.client)
                  numc1.push(item2.data.client)
                }
              }
            }
          } else {
            nump++
            //totalp++
            if(numc.indexOf(item2.data.client) === -1) {
              numc.push(item2.data.client)
              numc2.push(item2.data.client)
            }
          }
        }
      }

      tmp.push({
        "x": Moment(start).add(i*7, 'days').format('DD/MM'),
        "y": num,
      })
      tmpg.push({
        "x": Moment(start).add(i*7, 'days').format('DD/MM'),
        "y": numg,
      })
      tmpp.push({
        "x": Moment(start).add(i*7, 'days').format('DD/MM'),
        "y": nump,
      })
      tmpc.push({
        "x": Moment(start).add(i*7, 'days').format('DD/MM'),
        "y": numc.length,
      })
      tmpc1.push({
        "x": Moment(start).add(i*7, 'days').format('DD/MM'),
        "y": numc1.length,
      })
      tmpc2.push({
        "x": Moment(start).add(i*7, 'days').format('DD/MM'),
        "y": numc2.length,
      })

    }

    for(var client3 of this.state.clients) {
      var add = true
      for(var n of cls) {
        if(n === client3.id) {
          add = false
        }
      }
      if(add) {
        inactive.push(client3)
      }
    }

    var tmpData = [{
      "id": lang.t('admin:label.total'),
      "color": global.themeColors[2],
      data: tmp
    },
    {
      "id": lang.t('admin:label.group'),
      "color": global.themeColors[0],
      data: tmpg
    },
    {
      "id": lang.t('admin:label.11'),
      "color": global.themeColors[1],
      data: tmpp
    }]

    var tmpData2 = [{
      "id": lang.t('admin:label.total'),
      "color": global.themeColors[2],
      data: tmpc
    },
    {
      "id": lang.t('admin:label.group'),
      "color": global.themeColors[0],
      data: tmpc1
    },
    {
      "id": lang.t('admin:label.11'),
      "color": global.themeColors[1],
      data: tmpc2
    }]

    this.setState({
      dataTop: tmpData,
      dataClients: tmpData2,
    })
  }



  // Display stuff ------------------------------------------------------------



  renderAvatar(item) {
    for(var client of global.clients) {
      if(client.id === item) {
        return (
          HelperClients.getImage(client)
        )
      }
    }
  }


  renderStaff(active) {
    var num = 0
    for(var item of this.state.trainer) {
      if(item.data.status === 'active' && active) {
        num++
      }
      if(item.data.status !== 'active' && !active) {
        num++
      }
    }
    return num
  }


  renderSessionsNum(group) {
    var num = 0
    for(var item of this.state.sessions) {
      if(Moment(item.data.timestamp, 'X') > Moment().add(-30, 'days') && Moment(item.data.timestamp, 'X') < Moment()) {
        if(item.data.group && group && !item.data.availability) {
          num++
        }
        if(!item.data.group && !group && (!item.data.availability || (item.data.availabliltiy && item.data.attendance === 3))) {
          num++
        }
      }
    }
    return num
  }


  renderTop() {
    return (
      <div className="mt-20">
        <div className="col-4 mb-10">
          <h3 className="mb-20">{lang.t('header:title.clients')}</h3>
          <div className='info large col-6'>
            <label>{lang.t('overview:staffactive')}</label>
            <p>{this.state.clients.length}</p>
          </div>
          <div className='info large col-6'>
            <label>{lang.t('overview:staffinactive')}</label>
            <p>{global.clientsInactive.length}</p>
          </div>
        </div>
        <div className="col-4 mb-10">
          <h3 className="mb-20">{lang.t('header:title.staffoverview')}</h3>
          <div className='info large col-6'>
            <label>{lang.t('overview:staffactive')}</label>
            <p>{this.renderStaff(true)}</p>
          </div>
          <div className='info large col-6'>
            <label>{lang.t('overview:staffinactive')}</label>
            <p>{this.renderStaff(false)}</p>
          </div>
        </div>
        <div className="col-4 mb-10">
          <h3 className="mb-20">{lang.t('header:title.last30days')}</h3>
          <div className='info large col-6'>
            <label>{lang.t('overview:sessions')}</label>
            <p>{this.renderSessionsNum(false)}</p>
          </div>
          <div className='info large col-6'>
            <label>{lang.t('overview:group')}</label>
            <p>{this.renderSessionsNum(true)}</p>
          </div>
        </div>
        <div className="clear"></div>
      </div>
    )
  }


  renderMostSessions() {
    if(this.state.clients.length === 0) {
      return (
        <div className="empty pt-50">
          <h4>{lang.t('header:title.clientsno')}</h4>
        </div>
      )
    } else {
      
      var list = this.state.clients
      list.sort((a,b) => b.total - a.total);
      return (
        <div className="chart clear">
          {list.slice(0, 3).map(item => (
            <div className="list bare" key={item.id}>
              <Link to={'/clients/'+item.id+'/sessions'}>
              {this.renderAvatar(item.id)}
                <div className="main">
                  <h4>{item.data.name}</h4>
                  <p>{item.sessions} {item.sessions === 1 ? lang.t('admin:label.11session') : lang.t('admin:label.11sessions')}<br/>{item.group} {item.group === 1 ? lang.t('admin:label.groupsession') : lang.t('admin:label.groupsessions')}</p>
                </div>
                <div className="clear"></div>
              </Link>
            </div>
          ))}
        </div>
      )
    }
  }


  renderIncome() {
    if(this.state.clientsIn.length === 0) {
      return (
        <div className="empty pt-50">
          <h4>{lang.t('header:title.clientsno')}</h4>
        </div>
      )
    } else {
      
      var list = this.state.clientsIn
      list.sort((a,b) => b.income - a.income);
      return (
        <div className="clear">
          {list.slice(0, 3).map(item => (
            <div className="list bare" key={item.id}>
              <Link to={'/clients/'+item.id+'/details'}>
              {this.renderAvatar(item.id)}
                <div className="main">
                  <h4>{item.name}</h4>
                  <p>{global.curSym}{item.income === undefined ? '0.00' : item.income.toFixed(2)}<br/>{item.payments} {item.payments === 1 ? lang.t('admin:label.payment') : lang.t('admin:label.payments')}</p>
                </div>
                <div className="clear"></div>
              </Link>
            </div>
          ))}
        </div>
      )
    }
  }


  renderSessions() {
    return (
      <div className="col-12 mb-50">
        <h3 className="mb-20">{lang.t('header:title.sessionsweek')}</h3>
        <WidgetChartGraph data={this.state.dataTop}/>
      </div>
    )
  }


  renderClients() {
    return (
      <div className="mb-50 clear">
        <div className="col-6 mb-50">
          <h3 className="mb-20">{lang.t('header:title.activeclients')}</h3>
          <WidgetChartGraph data={this.state.dataClients}/>
        </div>
        <div className="col-3 mb-50">
          <h3 className="mb-10">{lang.t('header:title.mostactive')}</h3>
          {this.renderMostSessions()}
        </div>
        <div className="col-3 mb-50">
          <h3 className="mb-10">{lang.t('header:title.mostincome')}</h3>
          {this.renderIncome()}
        </div>
      </div>
    )
  }


  getNumber(status) {
    var num = 0
    for(var item of this.state.requestsList) {
      if(item.data.status === status) {
        num++
      }
    }
    return num
  }


  renderTrials() {
    return (
      <div className="mb-50 clear">
        <h3 className="mb-20">{lang.t('header:title.leads30days')}</h3>
        <div className='info large col-4'>
          <label>{lang.t('overview:leads')}</label>
          <p>{this.state.requestsList.length}</p>
        </div>
        <div className='info large col-4'>
          <label>{lang.t('overview:leadsnew')}</label>
          <p>{this.getNumber('new')}</p>
        </div>
        <div className='info large col-4'>
          <label>{lang.t('overview:leadsclients')}</label>
          <p>{this.getNumber('converted')+this.getNumber('added')}</p>
        </div>
        <div className="clear"></div>
      </div>
    )
  }


  renderContent() {
    if(HelperBusiness.getRoleIncl('')) {
      if(global.maxPlans === 99999) {
        return (
          <div className="content withtabs">
            {this.renderTop()}
            {this.renderSessions()}
            {this.renderClients()}
            {this.renderTrials()}
          </div>
        )
      } else {
        return (
          <div className="content withtabs">
            {this.renderTop()}
            <ListEmpty id='admin'/>
          </div>
        )
      }
    } else {
      return (
        <div className="content withtabs">
          <ListEmpty id='stats-locked' type='simple'/>
          <div className="clear space-40"></div>
        </div>
      )
    }
  }


  renderBase() {
    if(HelperBusiness.getRoleIncl('trainer,admin,finance,cs')) {
      return (
        <div className={'theme-'+global.spaceTheme}>
          {this.renderContent()}
          <Header type='admin' active='overview'/>
          <Navigation active='admin' />
        </div>
      )
    } else {
      return (
        <div className={'theme-'+global.spaceTheme}>
          <div className="content">
            <ListEmpty id='admin-locked' type='simple'/>
          </div>
          <Header type='simple' title={lang.t('nav:main.admin')}/>
          <Navigation active='admin' />
        </div>
      )
    }
  }


  render() {
    return (
      <AuthUserContext.Consumer>
        {authUser => (
          this.renderBase()
        )}
      </AuthUserContext.Consumer>
    )
  }
}



const condition = authUser => !!authUser;
export default withTranslation(['admin','common','header','overview','nav'])(withRouter(withAuthorization(condition)(AdminPage)));