import React, { Component } from 'react';
import Firebase from 'firebase';
import Moment from 'moment';
import 'moment/locale/es';
import 'firebase/functions';
import Navigation from '../../components/Navigation';
import Header from '../../components/Headers/calendar';
import HelperClients from '../../helper/Clients';
import HelperBilling from '../../helper/Billing';
import HelperBusiness from '../../helper/Business';
import HelperPage from '../../helper/Page';
import Message from '../../components/Message';
import * as ROUTES from '../../constants/routes';
import { withRouter } from 'react-router-dom';
import EventEmitter from '../../helper/Emitter';
import ListEmpty from '../../components/List/empty';
import ModalMessageSimple from '../../components/Modals/message-simple';
import { withTranslation } from "react-i18next";
import lang from 'i18next';
import { AuthUserContext, withAuthorization } from '../../components/Session';



class CheckinPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      id: '',
      item: null,
      clients: global.clients,
      clientsList: [],
      back: ROUTES.CALENDAR,
      link: '',
      search: '',
      showModalBack: false,
      showModalFull: false,
    };
  }


  componentDidMount() {
    HelperPage.initializeForm('Check In - PT Mate')

    EventEmitter.subscribe('userLoaded', (event) => this.configureData())
    EventEmitter.subscribe('clientsLoaded', (event) => this.configureData())
    EventEmitter.subscribe('sessionsLoaded', (event) => this.configureData())
    EventEmitter.subscribe('tokenLoaded', (event) => this.setState({token: global.clientToken}))

    this.configureData()
  }


  configureData() {
    var arr = this.props.location.pathname.split('/')
    var id = arr[arr.length-1]
    var back = ROUTES.CALENDAR
    var item = null

    back = '/calendar/group-session/'+id
    if(global.linkCli !== '') {
      back = global.linkCli
    }

    for(var session of global.sessions) {
      if(session.id === id && session.data.group) {
        item = session
      }
    }

    var tmp = []
    if(item !== null) {
      for(var itemc of global.clients) {
        tmp.push(itemc)
      }
    }

    this.setState({
      id: id,
      clients: tmp,
      back: back,
      item: item,
    }, () => {
      
    })
  }


  onChange(event) {
    this.setState({
      search: event.target.value
    }, () => {
      this.filterClients()
    })
  }


  filterClients() {
    var tmp = []
    for(var item of global.clients) {
      if(item.data.name.toLowerCase().indexOf(this.state.search.toLowerCase()) !== -1 && this.state.search !== '') {
        tmp.push(item)
      }
    }
    this.setState({
      clientsList: tmp
    })
  }



  // Configuration ------------------------------------------------------------



  getSessionName() {
    var label = lang.t('calendar:session.group')+' '
    if(this.state.item !== null) {
      if(this.state.item.data.group && this.state.item.data.client !== '') {
        label = this.state.item.data.client+' '
      }
      label += Moment(this.state.item.data.date, 'DD/MM/YYYY HH:mm').locale(lang.language).format('LT')
    }
    return label
  }


  getClient(id) {
    var client = null
    for(var item of this.state.clients) {
      if(item.id === id) {
        client = item
      }
    }
    return client
  }


  getClientName(id, type) {
    var label = ''
    for(var item of this.state.clients) {
      if(item.id === id) {
        var ar = item.data.name.split(' ')
        if(type === 'first') {
          label = ar[0]
        } else {
          if(ar.length > 1) {
            for(var i=1; i<ar.length; i++) {
              label += ar[i]+' '
            }
          }
        }
      }
    }
    return label
  }


  toggleCheckin(id) {
    var tmp = []
    if(this.state.item.data.clients !== undefined) {
      tmp = this.state.item.data.clients
    }
    if(tmp.indexOf(id) !== -1) {
      for(var i=0; i<tmp.length; i++) {
        if(tmp[i] === id) {
          tmp.splice(i, 1)
        }
      } 
    } else {
      if(this.state.item.data.max !== 0 && tmp.length === this.state.item.data.max) {
        this.setState({showModalFull: true})
      } else {
        tmp.push(id)
      }
    }
    Firebase.database().ref('/sessions/'+global.uid+'/'+this.state.item.id).update({
      clients: tmp
    })
    this.setState({
      search: '',
      clientsList: [],
    })
  }


  updateClientAttendance(item) {
    var group = true
    var client = null
    var cclient = null
    var linked = 0
    var family = false

    var memberships = []
    if(this.state.item.data.memberships !== undefined) {
      memberships = this.state.item.data.memberships
    }

    for(var cl of global.clients) {
      if(cl.id === item) {
        client = cl
        cclient = cl
        if(cl.data.linked !== undefined) {
          linked = cl.data.linked.length
          family = true
        }
      }
    }
    // Family accounts
    if(client.data.parent !== undefined && client.data.parent !== '') {
      for(var cl2 of global.clients) {
        if(cl2.id === client.data.parent) {
          client = cl2
          family = true
        }
      }
    }
    
    if(client !== null && global.userChargeSessions) {
      var hasSub = false
      var credit = ''
      var credite = ''
      var amount = 1
      var total = 0
      var bl = 0
      var datee = Moment('01/01/3000', 'DD/MM/YYYY')

      for(var sub of client.subscriptions) {
        var valid = true
        if(memberships.length > 0) {
          valid = false
          if(memberships.indexOf(sub.id) !== -1) {
            valid = true
          }
        }
        if(valid) {
          if(sub.data.group === group && (sub.data.sessions === 0 || sub.data.sessions === undefined) && sub.data.status !== 'trialing' && (sub.data.account === undefined || sub.data.account === '' || sub.data.account === item)) {
            hasSub = true
          }
        }
      }

      var avail = 0
      var availTotal = 9999
      if(!hasSub) {
        avail = HelperBilling.getClientSessionDebit(cclient, true, cclient.id, false, memberships)
        if(avail === undefined) {
          avail = 0
        }
      }

      availTotal = avail
      if(avail > 0 && !hasSub) {
        HelperBilling.chargeClientSessionDebit(client, group, item, memberships)
      }

      if(!hasSub && avail === 0) {
        // Family specific
        for(var cred of client.credits) {
          total = cred.data.sessionsTotal
          if(cred.data.group === group && cred.data.account === item) {
            credit = cred.id
            amount = total+1
            bl = cred.data.sessionsPaid-cred.data.sessionsTotal
            availTotal = cred.data.sessionsPaid-amount
          }
        }
        // Common and non-family
        for(var cred1 of client.credits) {
          total = cred1.data.sessionsTotal
          if(cred1.data.group === group && bl < 1 && (cred1.data.account === '' || cred1.data.account === undefined)) {
            if(cred1.data.sessionsPaid > cred1.data.sessionsTotal || !family) {
              credit = cred1.id
              amount = total+1
              availTotal = cred1.data.sessionsPaid-amount
            }
          }
        }
        bl = 0
        // Family specific
        for(var crede of client.creditse) {
          var valid3 = true
          if(memberships.length > 0) {
            valid3 = false
            if(memberships.indexOf(crede.data.product) !== -1) {
              valid3 = true
            }
          }
          if(valid3) {
            total = crede.data.sessionsTotal
            if(crede.data.group === group && Moment(crede.data.expires, 'X') > Moment() && Moment(crede.data.expires, 'X') < datee && crede.data.sessionsPaid > crede.data.sessionsTotal) {
              if(!crede.data.group && crede.data.account === item) {
                credite = crede.id
                amount = total+1
                datee = Moment(crede.data.expires, 'X')
                bl = crede.data.sessionsPaid-crede.data.sessionsTotal
                availTotal = crede.data.sessionsPaid-amount
              }
            }
          }
        }
        // Common and non-family
        for(var crede1 of client.creditse) {
          var valid4 = true
          if(memberships.length > 0) {
            valid4 = false
            if(memberships.indexOf(crede1.data.product) !== -1) {
              valid4 = true
            }
          }
          if(valid4) {
            total = crede1.data.sessionsTotal
            if(crede1.data.group === group && Moment(crede1.data.expires, 'X') > Moment() && Moment(crede1.data.expires, 'X') < datee && crede1.data.sessionsPaid > crede1.data.sessionsTotal) {
              if(bl < 1 && (crede1.data.account === '' || crede1.data.account === undefined)) {
                if(crede1.data.sessionsPaid > crede1.data.sessionsTotal) {
                  credite = crede1.id
                  amount = total+1
                  datee = Moment(crede.data.expires, 'X')
                  availTotal = crede1.data.sessionsPaid-amount
                }
              }
            }
          }
        }

        if(credit === '' && credite === '') {
          var acc = ''
          if(client.id !== item || linked > 0) {
            acc = client.id
          }
          Firebase.database().ref('/clients/'+global.uid+'/'+client.id+'/credits').push({
            group: group,
            sessionsPaid: 0,
            sessionsTotal: 1,
            type: 'sessions',
            account: acc
          })
        } else {
          if(credite === '') {
            for(var cu1 of client.credits) {
              if(cu1.id === credit) {
                cu1.data.sessionsTotal = amount
              }
            }
            Firebase.database().ref('/clients/'+global.uid+'/'+client.id+'/credits/'+credit).update({
              sessionsTotal: parseInt(amount),
            })
          } else {
            for(var cu2 of client.creditse) {
              if(cu2.id === credite) {
                cu2.data.sessionsTotal = amount
              }
            }
            Firebase.database().ref('/clients/'+global.uid+'/'+client.id+'/credits/'+credite).update({
              sessionsTotal: parseInt(amount),
            })
          }
        }
      }

      if(availTotal < 2 && !hasSub && global.spaceEmailPacks) {
        var type = "one"
        if(availTotal == 0) {
            type = "none"
        }
        if(availTotal < 0) {
            type = "minus"
        }
        var callFunction = Firebase.functions().httpsCallable('sendNoSessions');
        callFunction({name: global.userName, space: global.userBusiness, email: client.data.email, type: type, id: global.uid}).then(function(result) {})
      }
    }
  }



  // Overlays ------------------------------------------------------------



  hideModals() {
    this.setState({
      showModalBack: false,
      showModalFull: false
    })
  }


  confirmAttendance() {
    this.hideModals()
    var tmp = []
    if(this.state.item.data.confirmed !== undefined) {
      tmp = this.state.item.data.confirmed
    }
    if(this.state.item.data.clients !== undefined) {
      for(var item of this.state.item.data.clients) {
        if(tmp.indexOf(item) === -1) {
          this.updateClientAttendance(item)
          tmp.push(item)
        }
      }
    }
    // Firebase
    Firebase.database().ref('/sessions/'+global.uid+'/'+this.state.item.id).update({
      attendance: 3,
      confirmed: tmp
    }).then(() => {
      setTimeout(() => {
        this.props.history.push('/calendar/group-session/'+this.state.item.id)
      }, 500);
    }).catch((error)=>{
      EventEmitter.dispatch('showMessageError', error.message);
    })
  }



  // Display stuff ------------------------------------------------------------


  
  renderList() {
    if(this.state.item !== null) {
      if(this.state.item.data.clients !== undefined) {
        var list = this.state.item.data.clients
        return (
          <div>
            {list.map((item) => (
              <div className="list bare static" key={item}>
                {HelperClients.getImage(this.getClient(item))}
                <div className="main">
                  <h4>{this.getClientName(item, 'first')}</h4>
                  <p>{this.getClientName(item, 'last')}</p>
                </div>
                <div className="clear"></div>
              </div>
            ))}
          </div>
        )
      }
    }
  }


  renderSearch() {
    return (
      <div className="run-search">
        <input type="text" placeholder={lang.t('calendar:label.starttypingmembername')} value={this.state.search} onChange={(val) => this.onChange(val)}/>
      </div>
    )
  }


  renderResultStatus(item) {
    var contains = false
    if(this.state.item.data.clients !== undefined) {
      if(this.state.item.data.clients.indexOf(item.id) !== -1) {
        contains = true
      }
    }
    if(contains) {
      return (
        <div><span className="pill green">{lang.t('calendar:label.checkedin')}</span><strong>{item.data.name}</strong><br/>{lang.t('calendar:label.clicktocheckout')}</div>
      )
    } else {
      return (
        <div><strong>{item.data.name}</strong><br/>{lang.t('calendar:label.clicktocheckin')}</div>
      )
    }
  }


  renderResults() {
    if(this.state.clientsList.length > 0) {
      var list = this.state.clientsList
      list.sort((a,b) => a.data.name.localeCompare(b.data.name))
      return (
        <div className="run-members">
          {list.map((item) => (
            <div className="run-item" onClick={() => this.toggleCheckin(item.id)} key={item.id}>
              {this.renderResultStatus(item)}
            </div>
          ))}
        </div>
      )
    } else {
      if(this.state.search !== '') {
        return (
          <div className="run-members">
            <div className="run-item idle">
              <div>{lang.t('calendar:label.nomembersfound')}</div>
            </div>
          </div>
        )
      }
    }
  }


  renderChecked() {
    if(this.state.item.data.clients === undefined) {
      return ''
    } else {
      if(this.state.item.data.clients.length > 0) {
        return this.state.item.data.clients.length+' '+lang.t('calendar:label.checkedin')
      }
    }
  }


  renderBase() {
    if(HelperBusiness.getRoleIncl('assistant,assistant2,trainer')) {
      if(this.state.item !== null) {
        return (
          <div className={'run theme-'+global.spaceTheme}>
            {this.renderSearch()}
            {this.renderResults()}
            <div className="header">
              <div className="content">
                <div className="back lft mr-10" onClick={() => this.setState({showModalBack: true})}>
                  <div className="circle"></div>
                  <div className="arrow"></div>
                </div>
                <h2>{this.getSessionName()}: {lang.t('calendar:label.checkin')}</h2>
              </div>
            </div>
            <div className="run-sidebar">
              <h4 className="mb-20">{this.renderChecked()}</h4>
              {this.renderList()}
            </div>
            <div className="bottom" style={{position:'fixed', bottom: '30px', left: '0px', width: '100%'}}>
              <button className="btn tertiary width-12" onClick={() => this.setState({showModalBack: true})}>{lang.t('common:button.done')}</button>
            </div>
            <Message/>
            <ModalMessageSimple type='goback' show={this.state.showModalBack} onHide={() => this.hideModals()} clickMainButton={() => this.confirmAttendance()}/>
            <ModalMessageSimple type='sessionfull' show={this.state.showModalFull} onHide={() => this.hideModals()} clickMainButton={() => this.hideModals()}/>
          </div>
        )
      } else {
        return (
          <div className={'run theme-'+global.spaceTheme}>
            <div className="header">
              <button className="back" onClick={() => this.goBack()}></button>
            </div>
          </div>
        )
      }
    } else {
      return (
        <div className={'theme-'+global.spaceTheme}>
          <div className="content">
            <ListEmpty id='running-locked' type='simple'/>
          </div>
          <Header type='simple'/>
          <Navigation active='clients' />
        </div>
      )
    }
  }


  render() {
    return (
      <AuthUserContext.Consumer>
        {authUser => (
          this.renderBase()
        )}
      </AuthUserContext.Consumer>
    )
  }
}



const condition = authUser => !!authUser;
export default withTranslation(['calendar','common'])(withRouter(withAuthorization(condition)(CheckinPage)));