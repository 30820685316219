import React, { Component } from 'react';
import Firebase from 'firebase';
import 'firebase/functions';
import { withRouter } from 'react-router-dom';
import { compose } from 'recompose';
import Message from '../../components/Message';
import Moment from 'moment';
import { withTranslation } from "react-i18next";
import lang from 'i18next';
import EventEmitter from '../../helper/Emitter';



class StaffSetupPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      type: 'form',
      email: '',
      password: '',
      error1: null,
      error2: '',
      error3: false,
      password1: '',
      password2: '',
      error: [false, false, false],
      values: [global.userName, global.userPhone],
      birth: global.userBday.format('YYYY-MM-DD'),
      active: false
    };
  }


  componentDidMount() {
    document.title = 'Staff Setup - PT Mate'
    var arr = this.props.location.pathname.split('/')
    var id = arr[arr.length-1]
    global.showOverview = false
    setTimeout(() => {
      if(Firebase.auth().currentUser !== null && !this.state.active) {
        if(Firebase.auth().currentUser.displayName === 'staff') {
          global.uid = Firebase.auth().currentUser.uid;
          global.uidUser = Firebase.auth().currentUser.uid;
          this.loadUserData()
          this.setState({
            type: 'form2',
            active: true
          })
        } else {
          window.location = '/overview'
        }
      } else {
        if(id !== 'setup' && id !== 'staff') {
          this.logIn(id, 'password')
        }
      }
    }, 550);
    EventEmitter.subscribe('setupUserLoaded', (event) => this.updateUser())
  }


  logIn(email, password) {
    Firebase.auth().signInWithEmailAndPassword(email, password)
      .then(() => {
        global.uid = Firebase.auth().currentUser.uid;
        global.uidUser = Firebase.auth().currentUser.uid;
        if(Firebase.auth().currentUser.displayName === 'staff') {
          this.loadUserData()
          this.setState({
            type: 'form2'
          })
        } else {
          window.location = '/overview'
        }
      })
      .catch(error => {
        this.setState({ error1: error });
      });
  }


  loadUserData() {
    let itemsRef = Firebase.database().ref('/usersStaff/'+global.uidUser);
		itemsRef.on("value", function(snapshot) {
			if (snapshot.exists()) {
        var data = snapshot.val();
        global.userBday = Moment(data.birth, 'DD/MM/YYYY')
		  	global.userPhone = data.phone
        global.userEmail = data.email
        global.userName = data.name
        global.userRole = data.role
		  	global.userImage = ''
		  	global.userImageDate = Moment(data.imageDate, 'DD/MM/YYYY HH:mm')
				global.userImage = data.image
        global.userCountry = data.country
        global.uid = data.space
        global.userStaffStatus = data.status

        EventEmitter.dispatch('setupUserLoaded', 'loaded');
      }
    });
  }


  updateUser() {
    var birth = global.userBday.format('YYYY-MM-DD')
    if(birth === '1900-01-01') {
      birth = '1990-01-01'
    }
    this.setState({
      values: [global.userName, global.userPhone],
      birth: birth
    })
  }


  onChange = event => {
    this.setState({ [event.target.name]: event.target.value });
  }


  setPassword() {
    this.setState({error2: ''})
    if(this.state.password1 === this.state.password2 && this.state.password1 !== '') {
      Firebase.auth().currentUser.updatePassword(this.state.password1).then(() => {
        this.setState({
          type: 'form3'
        })
      }).catch((error) => {
        this.setState({error2: lang.t('auth:error.setuppass1')})
      });
    } else {
      this.setState({error2: lang.t('auth:error.setuppass2')})
    }
  }


  onChange2 = event => {
    var tmp = this.state.values
    tmp[parseInt(event.target.name)] = event.target.value
    this.setState({ values: tmp });
  }


  onChangeBirth = event => { 
    var valid = Moment(event.target.value, 'YYYY-MM-DD').isValid()
    if(valid) {
      //this.setState({birth: Moment(event.target.value, 'YYYY-MM-DD').format('DD/MM/YYYY')});
      this.setState({birth: Moment(event.target.value, 'YYYY-MM-DD').format('YYYY-MM-DD')});
    }
  }


  finishSetup() {
    this.setState({error3: false})
    if(this.state.values[0] !== '') {
      Firebase.database().ref('/spaces/'+global.uid+'/staff/'+global.uidUser).update({
        name: this.state.values[0],
      })
      Firebase.database().ref('/usersStaff/'+global.uidUser).update({
        name: this.state.values[0],
        phone: this.state.values[1],
        birth: Moment(this.state.birth, 'YYYY-MM-DD').format('DD/MM/YYYY'),
      }).then(() => {
        window.location = '/overview'
      }).catch((error)=>{
        EventEmitter.dispatch('showMessageError', error.message);
      })
    } else {
      this.setState({
        error3: true
      })
    }
  }


  logout() {
    Firebase.auth().signOut()
    this.setState({ type: 'form' })
  }



  // Display stuff ------------------------------------------------------------



  renderError2() {
    if(this.state.error2 !== '') {
      return (
        <p className="error">{this.state.error2}</p>
      )
    }
  }


  renderError3() {
    if(this.state.error3) {
      return (
        <p className="error">{lang.t('auth:error.setupname')}</p>
      )
    }
  }


  renderLogout() {
    if(Firebase.auth().currentUser !== null) {
      return (
        <div style={{position: 'fixed', top: 15, right: 15}}>
          <button className="btn tertiary small close" onClick={() => this.logout()}>{lang.t('auth:button.logout')}</button>
        </div>
      )
    }
  }


  renderForm3() {
    if(this.state.type === 'form3') {
      return (
        <div>
          <p>{lang.t('auth:setup.page3.text')}</p>
          <div className="sv-20"></div>
          <label>{lang.t('auth:setup.name')}</label>
          <input type="text" value={this.state.values[0]} onChange={this.onChange2} name="0" />
          <div className="sv-20"></div>
          <label>{lang.t('auth:setup.phone')}</label>
          <input type="number" value={this.state.values[1]} onChange={this.onChange2} name="1" />
          <div className="sv-20"></div>
          <label>{lang.t('auth:setup.dob')}</label>
          <input type="date" value={this.state.birth} onChange={this.onChangeBirth} name="2" />
          {this.renderError3()}
          <div className="sv-30"></div>
        <button className="btn primary" onClick={() => this.finishSetup()}>{lang.t('auth:button.letsgo')}</button>
        </div>
      )
    }
  }


  renderForm2() {
    if(this.state.type === 'form2') {
      return (
        <div>
          <p>{lang.t('auth:setup.password.text1')} {this.state.values[0]}. {lang.t('auth:setup.password.text2')}</p>
          <div className="sv-20"></div>
          <label>{lang.t('auth:setup.password.field1')}</label>
          <input type="password" placeholder={lang.t('auth:setup.password.field1.placeholder')} value={this.state.password1} onChange={this.onChange} name="password1" />
          <div className="sv-20"></div>
          <label>{lang.t('auth:setup.password.field2')}</label>
          <input type="password" placeholder={lang.t('auth:setup.password.field2.placeholder')} value={this.state.password2} onChange={this.onChange} name="password2" />
          {this.renderError2()}
          <div className="sv-30"></div>
        <button className="btn primary" onClick={() => this.setPassword()}>{lang.t('auth:button.next')}</button>
        </div>
      )
    }
  }


  renderForm() {
    if(this.state.type === 'form') {
      return (
        <div>
          <p className="mb-20">{lang.t('auth:setup.text')}</p> 
          <label>{lang.t('auth:login.email')}</label>
          <input type="text" placeholder={lang.t('auth:login.email.placeholder')} value={this.state.email} onChange={this.onChange} name="email" />
          <div className="sv-20"></div>
          <label>{lang.t('auth:login.password')}</label>
          <input type="password" placeholder={lang.t('auth:login.password.placeholder')} value={this.state.password} onChange={this.onChange} name="password" />
          <div className="sv-30"></div>
          <button className="btn primary" onClick={() => this.logIn(this.state.email, this.state.password)}>{lang.t('auth:button.getstarted')}</button>
          {this.state.error1 && <p className="error pt-10">{this.state.error1.message}</p>}
        </div>
      )
    }
  }


  render() {
    return (
      <div className="content login">
        <div className="box login" style={{height: 600, marginTop: -300}}>
          <div style={{textAlign: 'center'}}>
            <img src="/img/logo.svg" alt="PT Mate Business Portal"/>
          </div>
          <h1 className="mt-20 mb-30">{lang.t('auth:setup.title')}</h1>
          {this.renderForm()}
          {this.renderForm2()}
          {this.renderForm3()}
          {this.renderLogout()}
        </div>
        <Message/>
      </div>
    )
  }
}

const StaffSetupForm = compose(
  withRouter,
)(StaffSetupPage);

export default withTranslation(['auth','common'])(StaffSetupForm);
