import React, { Component } from 'react';
import Firebase from 'firebase';
import Moment from 'moment';
import 'moment/locale/es';
import Connector from '../../data/Connector';
import Cal from '../../helper/Calendar';
import HelperBusiness from '../../helper/Business';
import { Link, withRouter } from 'react-router-dom';
import HelperPage from '../../helper/Page';
import EventEmitter from '../../helper/Emitter';
import Navigation from '../../components/Navigation';
import Header from '../../components/Headers/form';
import ListEmpty from '../../components/List/empty';
import ModalMessageSimple from '../../components/Modals/message-simple';
import ModalVideo from '../../components/Modals/video';
import { withTranslation } from "react-i18next";
import lang from 'i18next';
import { AuthUserContext, withAuthorization } from '../../components/Session';



class ClientsPlanPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      id: global.currentClient,
      item: null,
      client: null,
      name: 'Client',
      plans: global.clientPlans,
      planId: '',
      sessionsTraining: global.sessionsTraining,
      archiveTraining: global.archiveTraining,
      show: 'all',
      link: '',
      url: '',
      showModalDelete: false,
      showModalVideo: false,
    };
  }


  componentDidMount() {
    HelperPage.initializeForm('Training Plan - Client Details - PT Mate')
    HelperPage.checkMessage()
    this._isMounted = true

    var arr = this.props.location.pathname.split('/')
    global.currentClient = arr[arr.length-3]
    this.setState({
      planId: arr[arr.length-1]
    })
    global.linkPro = '/clients/'+arr[arr.length-3]+'/plan/'+arr[arr.length-1]

    EventEmitter.subscribe('clientsLoaded', (event) => this.getClient())
    EventEmitter.subscribe('sessionsLoaded', (event) => this.configureData())
    EventEmitter.subscribe('archiveLoaded', (event) => this.configureData())
    EventEmitter.subscribe('clientsLoaded', (event) => {
      this.getClient()
    })

    global.planLocked = true
    this.getClient()
  }


  componentWillUnmount() {
    this._isMounted = false
  }


  getClient() {
    if(this._isMounted) {
      for(var client of global.clients) {
        if(client.id === global.currentClient) {
          this.setState({
            id: client.id,
            client: client,
            name: client.data.name,
            link: '/clients/'+client.id+'/plans'
          }, () => {
            if(global.plansUid !== this.state.client.data.uid && this.state.client.data.uid !== '') {
              global.plansUid = this.state.client.data.uid
              Connector.loadPlansClient((result) => {
                this.configureData()
                this.setState({
                  plans: global.clientPlans
                })
              })
            }
            this.configureData()
          });
        }
      }
    }
  }


  configureData() {
    if(this._isMounted) {
      var item = null
      for(var plan of global.clientPlans) {
        if(plan.id === this.state.planId) {
          item = plan
        }
      }
      this.setState({
        sessionsTraining: global.sessionsTraining,
        archiveTraining: global.archiveTraining,
        item: item
      })
    }
  }



  // Info stuff ------------------------------------------------------------



  getCompliance() {
    var done = 0
    var total = 0
    var color = ' green'
    var ids = []
    var pids = []

    var start = Moment(this.state.item.data.date, 'DD/MM/YYYY HH:mm').startOf('day')
    for(var i=0; i<this.state.item.weeks.length; i++) {
      var d1 = Moment(this.state.item.data.date, 'DD/MM/YYYY HH:mm').add(7*i, 'days')
      var d2 = Moment(this.state.item.data.date, 'DD/MM/YYYY HH:mm').add(7*i+1, 'days')
      var d3 = Moment(this.state.item.data.date, 'DD/MM/YYYY HH:mm').add(7*i+2, 'days')
      var d4 = Moment(this.state.item.data.date, 'DD/MM/YYYY HH:mm').add(7*i+3, 'days')
      var d5 = Moment(this.state.item.data.date, 'DD/MM/YYYY HH:mm').add(7*i+4, 'days')
      var d6 = Moment(this.state.item.data.date, 'DD/MM/YYYY HH:mm').add(7*i+5, 'days')
      var d7 = Moment(this.state.item.data.date, 'DD/MM/YYYY HH:mm').add(7*i+6, 'days')

      var ar1 = this.state.item.weeks[i].data.day1.split(',')
      var ar2 = this.state.item.weeks[i].data.day2.split(',')
      var ar3 = this.state.item.weeks[i].data.day3.split(',')
      var ar4 = this.state.item.weeks[i].data.day4.split(',')
      var ar5 = this.state.item.weeks[i].data.day5.split(',')
      var ar6 = this.state.item.weeks[i].data.day6.split(',')
      var ar7 = this.state.item.weeks[i].data.day7.split(',')
      ar1.splice(0, 1)
      ar2.splice(0, 1)
      ar3.splice(0, 1)
      ar4.splice(0, 1)
      ar5.splice(0, 1)
      ar6.splice(0, 1)
      ar7.splice(0, 1)

      if(d1 < Moment()) {
        total += ar1.length
      }
      if(d2 < Moment()) {
        total += ar2.length
      }
      if(d3 < Moment()) {
        total += ar3.length
      }
      if(d4 < Moment()) {
        total += ar4.length
      }
      if(d5 < Moment()) {
        total += ar5.length
      }
      if(d6 < Moment()) {
        total += ar6.length
      }
      if(d7 < Moment()) {
        total += ar7.length
      }
    
      var art = []
      for(var a1 of ar1) { art.push(a1) }
      for(var a2 of ar2) { art.push(a2) }
      for(var a3 of ar3) { art.push(a3) }
      for(var a4 of ar4) { art.push(a4) }
      for(var a5 of ar5) { art.push(a5) }
      for(var a6 of ar6) { art.push(a6) }
      for(var a7 of ar7) { art.push(a7) }

      for(var session of this.state.sessionsTraining) {
        
        var date = Moment(session.data.date, 'DD/MM/YYYY HH:mm')
        //if(date > start && session.data.template === this.state.item.id && date < Moment().add(1, 'day')) {
        if(date > start && art.indexOf(session.program[0].id) !== -1 && date < Moment().add(1, 'day')) {
          if(session.data.attendance === 3 && ids.indexOf(session.id) === -1 && pids.indexOf(session.program[0].id) === -1 && session.data.uid === this.state.client.data.uid) {
            done++
            ids.push(session.id)
            pids.push(session.program[0].id)
          }
        }
      }
      
      
    }

    var val = parseInt((done/total)*100)
    if(val < 85 && val > 50) {
      color = ' yellow'
    } else if(val < 51 && val > 25) {
      color = ' orange'
    } else if(val < 26) {
      color = ' red'
    }
    return [val, color]
  }


  getProgramStatus(item, num, day) {
    var label = 'green'
    var text = lang.t('clients:label.pending')
    var ids = []

    if(this.state.item.data.date !== undefined) {
      var start = Moment(this.state.item.data.date, 'DD/MM/YYYY HH:mm').startOf('day').format('X')
      var end = Moment(this.state.item.data.date, 'DD/MM/YYYY HH:mm').startOf('day').add(7*this.state.item.weeks.length+1, 'days').format('X')
      
      for(var session of this.state.sessionsTraining) {
        var sdate = Moment(session.data.date, 'DD/MM/YYYY HH:mm').format('X')
        
        if(session.program[0] !== undefined && session.data.uid === this.state.client.data.uid) {
          if(session.program[0].id === item && sdate > start && sdate < Moment().format('X') && sdate < end && ids.indexOf(session.program[0].id) === -1) {
            label = 'red'
            text = lang.t('clients:label.notdone')
            ids.push(session.program[0].id)
            if(session.data.attendance === 3) {
              label = 'green prominent'
              text = lang.t('clients:label.done')
            }
          }
        }
      } 
    }
    return <div className={'status '+label}>{text}</div>
  }


  getLink(num, id, day) {
    var label = '/clients/'+this.state.client.id+'/plan-program/'+this.state.item.id+'/'+id
    if(this.state.item.data.date !== undefined) {
      var start = Moment(this.state.item.data.date, 'DD/MM/YYYY HH:mm').format('X')
      var end = Moment(this.state.item.data.date, 'DD/MM/YYYY HH:mm').add(7*this.state.item.weeks.length+1, 'days').format('X')
      for(var session of this.state.sessionsTraining) {
        var d1 = Moment(this.state.item.data.date, 'DD/MM/YYYY HH:mm').add((num-7), 'days').format('DD/MM/YYYY')
        var dt = Moment(session.data.date, 'DD/MM/YYYY HH:mm').format('DD/MM/YYYY')
        var sdate = Moment(session.data.date, 'DD/MM/YYYY HH:mm').format('X')

        if(session.program[0] !== undefined) {
          if(session.data.uid === this.state.client.data.uid && (d1 === dt || session.program[0].id === id) && sdate > start && sdate < end && sdate < Moment().format('X')) {
            label = '/calendar/session/'+session.id
          }
        }
        
      }
    }
    return label
  }



  // Modals ------------------------------------------------------------



  hideModals() {
    this.setState({
      showModalDelete: false,
      showModalVideo: false,
    })
  }


  deletePlan() {
    this.hideModals()
    for(var pl of global.plans) {
      if(pl.id === this.state.item.id && pl.data.sent !== undefined) {
        if(pl.data.sent.indexOf(this.state.client.id) !== -1) {
          var sent = pl.data.sent
          for(var i=sent.length-1; i>-1; i--) {
            if(sent[i] === this.state.client.id) {
              sent.splice(i, 1)
            }
          }
          Firebase.database().ref('/plans/'+global.uid+'/'+pl.id).update({
            sent: sent
          })
        }
      }
    }
    
    Firebase.database().ref('/plans/'+this.state.client.data.uid+'/'+this.state.item.id).remove()
    .then((data)=>{
      global.message = lang.t('messaging:message.plandeleted')
      this.props.history.push('/clients/'+this.state.client.id+'/plans')
    }).catch((error)=>{
      EventEmitter.dispatch('showMessageError', error.message);
    })
  }



  // Display stuff ------------------------------------------------------------



  renderCompliance() {
    if(this.state.item.data.date !== undefined) {
      return (
        <p className={this.getCompliance()[1]}>{this.getCompliance()[0]}%</p>
      )
    } else {
      return (
        <p>-</p>
      )
    }
  }


  renderStatus() {
    if(this.state.item.data.date === undefined) {
      return (
        <div>
          <p>-</p>
          <p style={{fontSize: 12}}>{lang.t('clients:label.notstartedyet')}</p>
        </div>
      )
    } else {
      var start = Moment(this.state.item.data.date, 'DD/MM/YYYY HH:mm')
      var end = Moment(this.state.item.data.date, 'DD/MM/YYYY HH:mm').add(7*this.state.item.weeks.length, 'days')
      var label = lang.t('clients:label.active')
      var date = ''
      if(end < Moment()) {
        label = lang.t('clients:label.done')
        date = lang.t('clients:label.finished')+' '+Moment(end).locale(lang.language).format('DD MMM YYYY')
      } else {
        date = lang.t('program:plan.week')+' '+(Moment().diff(start, 'weeks')+1)
      }
      return (
        <div>
          <p>{label}</p>
          <p style={{fontSize: 12}}>{date}</p>
        </div>
      )
    }
  }


  renderInfo() {
    if(this.state.item !== null) {
      return (
        <div className="box col-6 grey">
          <div className="info large col-6">
            <label>{lang.t('clients:label.status2')}</label>
            {this.renderStatus()}
          </div>
          <div className="info large col-6">
            <label>{lang.t('clients:label.compliance')}</label>
            {this.renderCompliance()}
          </div>
          <div className="clear"></div>
        </div>
      )
    }
  }


  renderProgramColor(id) {
    var label = ''
    for(var item of this.state.item.programs) {
      if(item.id === id) {
        label = Cal.getColor(item.data.time)
      }
    }
    return label
  }


  renderProgramData(id) {
    var mins = ''
    var exercises = ''
    var name = ''
    var time = 0
    for(var item of this.state.item.programs) {
      if(item.id === id) {
        name = item.data.name
        exercises = item.data.exercises+' '+lang.t('program:plan.movements')
        mins = Cal.getDuration(item.data.time)
        time = item.data.time
        if(item.data.exercises === 1) {
          exercises = item.data.exercises+' '+lang.t('program:plan.movement')
        }
      }
    }
    return [name, exercises, mins, time]
  }


  renderWeekday(id, day, num) {
    var programs = []
    for(var item of this.state.item.weeks) {
      if(item.id === id) {
        if(day === 1) {
          programs = item.data.day1.split(',')
        } else if(day === 2) {
          programs = item.data.day2.split(',')
        } else if(day === 3) {
          programs = item.data.day3.split(',')
        } else if(day === 4) {
          programs = item.data.day4.split(',')
        } else if(day === 5) {
          programs = item.data.day5.split(',')
        } else if(day === 6) {
          programs = item.data.day6.split(',')
        } else if(day === 7) {
          programs = item.data.day7.split(',')
        }
      }
    }
    programs.splice(0,1)
    for(var i=programs.length-1; i>=0; i--) {
      if(programs[i] === undefined) {
        programs.splice(i, 1)
      }
    }
    if(programs.length > 0) {
      return (
        <div>
          {programs.map((item) => (
            <div className="cal-session">
              <Link to={this.getLink(num, item, day)} key={item}>
                {this.getProgramStatus(item, num, day)}
                <div className={'icon clear '+this.renderProgramColor(item)}>
                  <p>{this.renderProgramData(item)[3]}'</p>
                </div>
                <p className="lft"><strong>{this.renderProgramData(item)[0]}</strong><br/>{this.renderProgramData(item)[2]}</p>
                <div className="clear"></div>
              </Link>
            </div>
          ))}
        </div>
      )
    } else {
      return (
        <div className="empty small pt-30">
          <h4>{lang.t('program:plan.restday')}</h4>
        </div>
      )
    }
  }


  renderToday(num, index) {
    var label = ''
    if(this.state.item.data.date !== null && this.state.item.data.date !== undefined) {
      var d1 = Moment(this.state.item.data.date, 'DD/MM/YYYY HH:mm').add(num+(index*7), 'days').format('DD/MM/YYYY')
      if(d1 === Moment().format('DD/MM/YYYY')) {
        label = ' '+lang.t('calendar:label.today-s')
      }
    }
    return label
  }


  renderWeeks() {
    if(this.state.item !== null) {
      return (
        <div className="col-12">
          {this.state.item.weeks.map((item, index) => (
            <div className="box mb-20" key={item.id}>
              <h3 className="mb-20">{item.data.name}</h3>
              <div className="calmain">
                <div style={{minWidth: '1200px'}}>
                  <div className="planweek clear calendar">
                    <div style={{minWidth: '1000px'}}>
                      <div className={'weekday cal-top lft'+this.renderToday(item.data.id*7, index)}>
                        <h3 className="mb-10">Day 1</h3>
                        {this.renderWeekday(item.id, 1, item.data.id*7)}
                      </div>
                      <div className={'weekday cal-top lft'+this.renderToday(item.data.id*7+1, index)}>
                        <h3 className="mb-10">Day 2</h3>
                        {this.renderWeekday(item.id, 2, item.data.id*7+1)}
                      </div>
                      <div className={'weekday cal-top lft'+this.renderToday(item.data.id*7+2, index)}>
                        <h3 className="mb-10">Day 3</h3>
                        {this.renderWeekday(item.id, 3, item.data.id*7+2)}
                      </div>
                      <div className={'weekday cal-top lft'+this.renderToday(item.data.id*7+3, index)}>
                        <h3 className="mb-10">Day 4</h3>
                        {this.renderWeekday(item.id, 4, item.data.id*7+3)}
                      </div>
                      <div className={'weekday cal-top lft'+this.renderToday(item.data.id*7+4, index)}>
                        <h3 className="mb-10">Day 5</h3>
                        {this.renderWeekday(item.id, 5, item.data.id*7+5)}
                      </div>
                      <div className={'weekday cal-top lft'+this.renderToday(item.data.id*7+5, index)}>
                        <h3 className="mb-10">Day 6</h3>
                        {this.renderWeekday(item.id, 6, item.data.id*7+5)}
                      </div>
                      <div className={'weekday cal-top lft'+this.renderToday(item.data.id*7+6, index)}>
                        <h3 className="mb-10">Day 7</h3>
                        {this.renderWeekday(item.id, 7, item.data.id*7+6)}
                      </div>
                      <div className="clear"></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
      )
    }
  }


  renderName() {
    if(this.state.client !== null) {
      return this.state.client.data.name
    }
  }


  renderSessions() {
    if(this.state.item.data.sessions !== 0) {
      return (
        <p className="mb-10"><span className="pill primary">{this.state.item.data.sessions} {this.state.item.data.sessions === 1 ? lang.t('program:plan.sessionsincluded') : lang.t('program:plan.sessionsincluded')}</span></p>
      )
    }
  }


  renderBasic() {
    if(this.state.item !== null) {
      return (
        <div className="program-header mb-50">
          <div className="icon large primary lft mr-20">
            <p>{this.state.item.weeks.length}</p>
          </div>
          <div className="main">
            <h2>{this.state.item.data.name}</h2>
            <p className="mb-10">{this.state.item.data.desc}</p>
            {this.renderSessions()}
            {this.renderVideo()}
          </div>
          <div className="clear"></div>
        </div>
      )
    }
  }


  renderVideo() {
    if(this.state.item.data.video !== undefined && this.state.item.data.video !== '') {
      if(this.state.item.data.video.indexOf('folder') !== -1 || this.state.item.data.video.indexOf('playlist') !== -1) {
        return (
          <a className="btn tertiary" href={this.state.item.data.video} target="_blank" rel="noopener noreferrer">{lang.t('clients:button.watchvideo')}</a>
        )
      } else {
        return (
          <button className="btn tertiary" onClick={() => this.setState({showModalVideo: true})}>{lang.t('clients:button.watchvideo')}</button>
        )
      }
    }
  }


  renderDelete() {
    if(this.state.item !== null) {
      return (
        <div>
          <button className="btn tertiary width-12 close" onClick={() => this.setState({showModalDelete: true})}>{lang.t('clients:button.deletetrainingplan')}</button>
          <div className="sv-40"></div>
        </div>
      )
    }
  }


  renderBase() {
    if(HelperBusiness.getRoleIncl('trainer')) {
      return (
        <div className={'theme-'+global.spaceTheme}>
          <div className="content">
            {this.renderBasic()}
            <div className="col-3 clear">&nbsp;</div>
            {this.renderInfo()}
            <div className="sv-40 clear"></div>
            {this.renderWeeks()}
            <div className="sv-20 clear"></div>
            {this.renderDelete()}
            <div className="sv-20"></div>
          </div>
          <Header title={lang.t('clients:title.trainingplanfor')+' '+this.renderName()} link={this.state.link}/>
          <Navigation active='clients' />
          <ModalMessageSimple type='deleteplanclient' show={this.state.showModalDelete} onHide={() => this.hideModals()} clickMainButton={() => this.deletePlan()}/>
          <ModalVideo type='deleteplanclient' show={this.state.showModalVideo} url={this.state.item === null ? '' : this.state.item.data.video} onHide={() => this.hideModals()}/>
        </div>
        
      )
    } else {
      return (
        <div className={'theme-'+global.spaceTheme}>
          <div className="content">
            <ListEmpty id='trainingplans-locked' type='simple'/>
          </div>
          <Header title={lang.t('clients:title.trainingplanfor')+' '+this.renderName()} link={this.state.link}/>
          <Navigation active='clients' />
        </div>
        
      )
    }
    
  }


  render() {
    return (
      <AuthUserContext.Consumer>
        {authUser => (
          this.renderBase()
        )}
      </AuthUserContext.Consumer>
    )
  }
}



const condition = authUser => !!authUser;
export default withTranslation(['clients','common','header','program','calendar','modals','messaging'])(withRouter(withAuthorization(condition)(ClientsPlanPage)));