import React, { Component } from 'react';
import { withTranslation } from "react-i18next";
import lang from 'i18next';


const DataMessageDouble = [
  {
    id: 'newclient1',
    title: 'modals:double.data1.title',
    text: "modals:double.data1.text",
    button: 'modals:double.data1.button',
    button2: 'modals:double.data1.button2',
  },
  {
    id: 'newclient2',
    title: 'modals:double.data2.title',
    text: "modals:double.data2.text",
    button: 'modals:double.data2.button',
    button2: 'modals:double.data2.button2',
  },
  {
    id: 'enablenutrition',
    title: 'modals:double.data3.title',
    text: "modals:double.data3.text",
    button: 'modals:double.data3.button',
    button2: 'modals:double.data3.button2',
  },
  {
    id: 'noshow',
    title: 'modals:double.data4.title',
    text: "modals:double.data4.text",
    button: 'modals:double.data4.button',
    button2: 'modals:double.data4.button2',
  },
  {
    id: 'saveform',
    title: 'modals:double.data5.title',
    text: "modals:double.data5.text",
    button: 'modals:double.data5.button',
    button2: 'modals:double.data5.button2',
  },
  {
    id: 'inviteclient',
    title: 'modals:double.data6.title',
    text: "modals:double.data6.text",
    button: 'modals:double.data6.button',
    button2: 'modals:double.data6.button2',
  },
  {
    id: 'notattended',
    title: 'modals:double.data7.title',
    text: "modals:double.data7.text",
    button: 'modals:double.data7.button',
    button2: 'modals:double.data7.button2',
  },
  {
    id: 'clientinvite',
    title: 'modals:double.data8.title',
    text: "modals:double.data8.text",
    button: 'modals:double.data8.button',
    button2: 'modals:double.data8.button2',
  },
  {
    id: 'family',
    title: 'modals:double.data9.title',
    text: "modals:double.data9.text",
    button: 'modals:double.data9.button',
    button2: 'modals:double.data9.button2',
  },
  {
    id: 'confirmdebitwarning',
    title: "modals:double.data10.title",
    text: "modals:double.data10.text",
    button: 'modals:double.data10.button',
    button2: 'modals:double.data10.button2',
  },
  {
    id: 'sendplan',
    title: "modals:double.data11.title",
    text: "modals:double.data11.text",
    button: 'modals:double.data11.button',
    button2: 'modals:double.data11.button2',
  },
]

class ModalMessageDouble extends Component {

  constructor(props) {
    super(props);
    this.state = {
      id: props.type,
      show: props.show,
      hidden: 'hidden',
      var: props.var
    };
    if(props.show) {
      this.show()
    }
  }


  componentWillReceiveProps(props) {
    this.setState({
      id: props.type,
      show: props.show,
      var: props.var,
    }, () => {
      if(this.state.show) {
        this.show()
      }
    })
  }


  show() {
    this.setState({
      show: true,
    })
    setTimeout(() => {
      const height = this.divElement.clientHeight
      this.setState({
        hidden: '',
        margin: -height/2
      });
    }, 100);
  }


  hide() {
    const height = this.divElement.clientHeight
    this.setState({
      hidden: 'hidden',
      margin: -height/2+100
    })
    setTimeout(() => {
      this.setState({show: false});
      this.props.onHide(false);
    }, 500);
  }


  clickMainButton() {
    this.hide()
    this.props.clickMainButton()
  }


  clickSecondaryButton() {
    this.hide()
    this.props.clickSecondaryButton()
  }


  render() {
    var data = null
    var text = ''
    for(var item of DataMessageDouble) {
      if(item.id === this.state.id) {
        data = item
      }
    }
    if(this.state.show && data !== null) {
      text = lang.t(data.text)
      text = text.replace('$var', this.state.var)
      return (
        <div className={'overlay '+this.state.hidden}>
          <div className="box auto" ref={ (divElement) => { this.divElement = divElement }} style={{marginTop: this.state.margin}}>
          <h2 className="lft mb-20">{lang.t(data.title)}</h2>
            <button className="btn tertiary small close rgt" onClick={() => this.hide()}>{lang.t('common:button.close')}</button>
            <p className="clear mb-30">{text}</p>
            <button className="btn primary mb-20" onClick={() => this.clickMainButton()}>{lang.t(data.button)}</button>
            <button className="btn tertiary width-12" onClick={() => this.clickSecondaryButton()}>{lang.t(data.button2)}</button>
          </div>
        </div>
      )
    } else {
      return null
    }
  }
}


export default withTranslation(['modals','common'])(ModalMessageDouble);