import Firebase from 'firebase';
import EventEmitter from '../helper/Emitter';
import Moment from 'moment';
import Cal from '../helper/Calendar';
import HelperBusiness from '../helper/Business';
import { ApolloClient, InMemoryCache, HttpLink } from '@apollo/client';


class Connector {

  setUser() {
    if(global.uid === '' || global.uid === undefined) {

      global.uid = Firebase.auth().currentUser.uid;
      global.uidUser = Firebase.auth().currentUser.uid;
      if(window.location.host === 'localhost:3000') {
        //global.uid = 'T36OTWHMlhSnywhkhp6Q5yJcWKh2'
        //global.uidUser = 'T36OTWHMlhSnywhkhp6Q5yJcWKh2'
        //global.uid = 'z9MIQXdZsVMngJOfzsTKS4K2BDT2'
        //global.uidUser = 'z9MIQXdZsVMngJOfzsTKS4K2BDT2'
      }

      // Payments & Billing
      global.prices = global.pricesAU
      global.userCurrency = "aud"
      global.curSym = "A$"
      global.stripeFee = '2.25% + A$0.30'

      global.userName = Firebase.auth().currentUser.displayName
      if(global.userName === 'client') {
        Firebase.auth().signOut() 
        setTimeout(() => {
          window.location = 'https://ptmate.me'
        }, 250);
      } else if(global.userName === 'staff') {
        // Staff user
        this.loadUserStaff((result) => {
          if(global.userStaffStatus === 'inactive') {
            if(window.location.href.indexOf('/staff/inactive') === -1) {
              window.location = '/staff/inactive'
            }
          } else {
            if(global.uid === '') {
              if(window.location.href.indexOf('/staff/search') === -1) {
                window.location = '/staff/search'
              }
            } else {
              this.loadStaffSpace((result) => {
                this.checkStaffActive()
                this.checkUserSubscription()
              })

              this.loadClients((result) => {
                if(global.clients.length > 0 && HelperBusiness.getRoleIncl('trainer,admin,cs')) {
                  this.loadChats((result) => {})
                  this.loadChatsGroup((result) => {})
                }
              })
              this.loadSessions((result) => {})
              this.loadRecurring((result) => {})
              this.loadPrograms((result) => {})
              this.loadPlans((result) => {})
              this.loadActivity((result) => {})
              this.loadExercises((result) => {})
              this.loadProducts((result) => {})
              this.loadPayments((result) => {})
              this.loadInvoices((result) => {})
              this.loadExpenses((result) => {})
              this.loadExpensesCat((result) => {})
              this.loadDocuments((result) => {})
              this.loadLog((result) => {})
              this.loadEvents((result) => {})
              this.loadCommunity((result) => {})
              this.loadClientGroups((result) => {})
              this.loadForms((result) => {})
              this.loadHabits((result) => {})
              this.loadStaff((result) => {})
              this.loadEmails((result) => {})
              this.loadSchedule((result) => {})
              this.loadLocations((result) => {
                // Check valid locations
                global.locations = []
                for(var loc of global.spaceLocations) {
                  if(loc.data.staff !== undefined) {
                    if(loc.data.staff.indexOf(global.uidUser) !== -1) {
                      global.locations.push(loc)
                    }
                  }
                }
                if(global.spaceLocations.length > 0 && global.spaceLocations.length !== global.locations.length) { 
                  if(global.locations.length > 0) {
                    global.loc = global.locations[0].id
                  } else {
                    global.loc = global.spaceLocations[0].id
                  }
                }
                if(global.spaceLocations.length > 0 && global.locations.length === 0) {
                  global.loc = 'notset'
                }
                EventEmitter.dispatch('locationsLoaded', 'loaded');
              })
            }
          }
        })
      } else {

        this.loadUserSpace((result) => {
          // Load business data
          if(global.uidUser !== undefined && global.uidUser !== '') {
            this.loadStripePlans((result) => {
              this.checkUserSubscription()
              this.loadStaff()
            })
          }
        })
        // Main business user
        this.loadUser((result) => {
          global.currentWeek = parseInt(Moment().format('w'));
          if(global.userPhone === '') {
            window.location.pathname = '/complete-signup'
          }
          if(global.userUser !== global.uidUser && global.userUser !== '') {
            global.uid = global.userUser
            global.currentAssistant = global.userUser
            global.userRole = 'assistant'
          }
          if(global.uidUser !== undefined && global.uidUser !== '') {
            this.loadClients((result) => {
              if(global.clients.length > 0) {
                this.loadChats((result) => {})
                this.loadChatsGroup((result) => {})
              }
            })
            this.loadSessions((result) => {})
            this.loadRecurring((result) => {})
            this.loadPrograms((result) => {})
            this.loadPlans((result) => {})
            this.loadActivity((result) => {})
            this.loadExercises((result) => {})
            this.loadProducts((result) => {})
            this.loadPayments((result) => {})
            this.loadInvoices((result) => {})
            this.loadExpenses((result) => {})
            this.loadExpensesCat((result) => {})
            this.loadDocuments((result) => {})
            this.loadLog((result) => {})
            this.loadEvents((result) => {})
            this.loadCommunity((result) => {})
            this.loadClientGroups((result) => {})
            this.loadForms((result) => {})
            this.loadUserSpaces()
            this.loadHabits((result) => {})
            this.loadEmails((result) => {})
            this.loadSchedule((result) => {})
            this.loadLocations((result) => {})

            this.initIntercom()
            
            if(global.userRole !== '') {
              this.loadSpaceImage()
            }
            this.loadPublic()

            setTimeout(() => {
              this.checkSessionsArchive()
              this.checkPaymentsArchive()
              this.checkEventsArchive()
              this.checkBadges()
            }, 15000);
          } else {
            Firebase.auth().signOut()
          }
        })
      }

      /*Firebase.database().ref('/payments/XiRDsN8lYwhkdqHOSFmaDBjjkE62/ch_3QQtZlBpcb6mneZP3bPxoK1S').update({
        amount: 21900,
        client: "-M3EXwrG00uoSxFK5NHa",
        date: "30/11/2024, 16:31",
        id: "ch_3QQtZlBpcb6mneZP3bPxoK1S",
        last4: "4994",
        name: "Subscription update",
        receipt: "https://pay.stripe.com/receipts/invoices/CAcQARoXChVhY2N0XzFHT2JBQkJwY2I2bW5lWlAo3PusugYyBtAZGZxfeDosFjCtQgQLjrnDnxsXAfBDJKeFnZ8CQyELSy6wXhzohArTL6pMXhMReytmm0o?s=ap",
        refunds: "",
        timestamp: 1732984282,
        type: "MasterCard",
        uid: "XiRDsN8lYwhkdqHOSFmaDBjjkE62",
      })*/

      //var callFunction = Firebase.functions().httpsCallable('testCreate');
      //callFunction({email: 'focusedpt@icloud.com', password: 'ptmate123', uid: 'GECxdCW4dSXIxwJvl4dgHr5iJmp1', displayName: "Craig"}).then(function(result) {});

      /*var callFunction = Firebase.functions().httpsCallable('confail');
      callFunction({account: 'acct_1OXtTTPDSN7svC1j'}).then(function(result) {
      //var sanitizedMessage = result.data.text;
      });*/

      //var callFunction = Firebase.functions().httpsCallable('testV2');
      //callFunction({email: 'focusedpt@icloud.com', password: 'ptmate123', uid: 'GECxdCW4dSXIxwJvl4dgHr5iJmp1', displayName: "Craig"}).then(function(result) {});
    
    }

    Moment.updateLocale("en", { week: {
      dow: 1, // First day of week is Monday
    }});
    
  }


  updateStripeSubscription() {
    var callFunction = Firebase.functions().httpsCallable('retrieveSubscription');
    callFunction({uid: global.uidUser, sub: global.userSubId, customer: global.userStripe}).then(function(result) {});
    global.syncDate = Moment().format('X')
    Firebase.database().ref('/users/'+global.uidUser).update({
      syncDate: parseInt(Moment().format('X'))
    })
    Firebase.database().ref('/spaces/'+global.uidUser).update({
      syncDate: parseInt(Moment().format('X'))
    })
  }


	loadClients(callback) {
		const itemsRef = Firebase.database().ref('/clients/'+global.uid);
    itemsRef.on('value', (snap) => {
      // get children as an array
      global.clients = [];
      global.clientsImages = [];
      global.clientsInactive = [];
      global.clientsTrial = [];
      global.sessionsTraining = []
      global.archiveTraining = []

      snap.forEach((child) => {

      	let aRef = Firebase.database().ref('/clients/'+global.uid+'/'+child.key+'/assessments/');
      	var assessments = []
      	aRef.once('value', (asnap) => {
      		asnap.forEach((achild) => {
      			assessments.push({
      				id: achild.key,
          			data: achild.val(),
      			});
      		});
      	});

        var parent = ''
        if(child.val().parent !== undefined) {
          parent = child.val().parent
        }

        var credits = []
        var creditse = []
        var subscriptions = []

        if(parent === '' || parent === undefined) {
          let cRef = Firebase.database().ref('/clients/'+global.uid+'/'+child.key+'/credits/');
          
          cRef.once('value', (csnap) => {
            csnap.forEach((cchild) => {
              if(cchild.val().expires === undefined) {
                credits.push({
                  id: cchild.key,
                    data: cchild.val(),
                });
              } else {
                creditse.push({
                  id: cchild.key,
                    data: cchild.val(),
                });
              }
              
            });
          });

          let sRef = Firebase.database().ref('/clients/'+global.uid+'/'+child.key+'/subscriptions/');
          sRef.once('value', (ssnap) => {
            ssnap.forEach((schild) => {
              subscriptions.push({
                id: schild.key,
                  data: schild.val(),
              });
            });
          });
        }

        let hRef = Firebase.database().ref('/clients/'+global.uid+'/'+child.key+'/history/');
        var history = []
        hRef.once('value', (hsnap) => {
          hsnap.forEach((hchild) => {
            history.push({
              id: hchild.key,
              data: hchild.val(),
            });
          });
        });

        let dRef = Firebase.database().ref('/clients/'+global.uid+'/'+child.key+'/documents/');
        var documents = []
        dRef.once('value', (dsnap) => {
          dsnap.forEach((dchild) => {
            documents.push({
              id: dchild.key,
              client: child.key,
              data: dchild.val(),
            });
          });
        });

        let nRef = Firebase.database().ref('/clients/'+global.uid+'/'+child.key+'/noshow/');
        var noshows = []
        nRef.once('value', (nsnap) => {
          nsnap.forEach((nchild) => {
            noshows.push({
              id: nchild.key,
              data: nchild.val(),
            });
          });
        });

        let fRef = Firebase.database().ref('/clients/'+global.uid+'/'+child.key+'/forms/');
      	var forms = []
      	fRef.once('value', (fsnap) => {
      		fsnap.forEach((fchild) => {
            let fsRef = Firebase.database().ref('/clients/'+global.uid+'/'+child.key+'/forms/'+fchild.key+'/sections/');
            var sections = []
            fsRef.once('value', (fssnap) => {
              fssnap.forEach((fschild) => {
                sections.push({
                  id: fschild.key,
                  data: fschild.val(),
                });
              });
            });
            let fhRef = Firebase.database().ref('/clients/'+global.uid+'/'+child.key+'/forms/'+fchild.key+'/notes/');
            var history = []
            fhRef.once('value', (fhsnap) => {
              fhsnap.forEach((fhchild) => {
                history.push({
                  id: fhchild.key,
                  data: fhchild.val(),
                });
              });
            });
            forms.push({
      				id: fchild.key,
          		data: fchild.val(),
              sections: sections,
              history: history,
      			});
      		});
      	});

        var del = false
        if(child.val().deleted !== undefined) {
          del = child.val().deleted
        }
        
        if(child.val().name === undefined) {
          //Firebase.database().ref('/clients/'+global.uid+'/'+child.key).remove()
          del = true
        }

        if(!del) {
          if(child.val().active || child.val().active === undefined) {
            global.clients.push({
              id: child.key,
              data: child.val(),
              assessments: assessments,
              credits: credits,
              creditse: creditse,
              subscriptions: subscriptions,
              history: history,
              forms: forms,
              documents: documents,
              noshow: noshows
            });
          } else {
            global.clientsInactive.push({
              id: child.key,
              data: child.val(),
              assessments: assessments,
              credits: credits,
              creditse: creditse,
              subscriptions: subscriptions,
              history: history,
              forms: forms,
              documents: documents,
              noshow: noshows
            });
          }
          
          global.clientsImages.push({
            id: child.key,
            image: ''
          })
        }
        

        if(child.val().image !== '') {
          if(child.val().uid === '') {
            if(child.val().image !== undefined) {
              this.loadClientImage(child.key, child.val().image)
            }
          } else {
            this.loadClientImage(child.key, '/images/users/'+child.val().uid+'.jpg')
          }
          
        }

        if(child.val().uid !== '') {
          this.loadTraining(child.val().uid)
          this.loadTrainingArchive(child.val().uid)
        }
      });
      EventEmitter.dispatch('clientsLoaded', 'loaded');
      global.clientsLoaded = true
      callback({success: true});
      if(global.clients.length > global.maxClients && global.maxClients !== 0) {
        this.reduceClientsPrograms()
      }
    
    });
	}


  loadClientImage(id, image) {
    if(image.indexOf('undefined') === -1) {
      Firebase.storage().ref().child(image).getDownloadURL().then((url) => {
        for(var item of global.clientsImages) {
          if(item.id === id) {
            item.image = url
            if(item.id === global.currentClient) {
              EventEmitter.dispatch('imageLoaded', 'loaded');
            }
          }
        }
      }).catch((error) => {
        for(var item of global.clientsImages) {
          if(item.id === id) {
            item.image = ''
            if(item.id === global.currentClient) {
              EventEmitter.dispatch('imageLoaded', 'loaded');
            }
          }
        }
        for(var item2 of global.clients) {
          if(item2.id === id) {
            item2.data.image = ''
          }
        }
        // Handle any errors
      })
    }
  }


  loadTraining(id) {
    const itemsRef = Firebase.database().ref('/sessions/'+id);
    itemsRef.on('value', (snap) => {
      // get children as an array
      snap.forEach((child) => {
        let wRef = Firebase.database().ref('/sessions/'+id+'/'+child.key+'/workout/');
        var program = []
        var comments = []
        wRef.once('value', (wsnap) => {
          wsnap.forEach((wchild) => {

            let bRef = Firebase.database().ref('/sessions/'+id+'/'+child.key+'/workout/'+wchild.key+'/blocks/');
            var blocks = []
            bRef.once('value', (bsnap) => {
              bsnap.forEach((bchild) => {
                blocks.push({
                  id: bchild.key,
                    data: bchild.val(),
                });
              });
            });

            program.push({
              id: wchild.key,
                data: wchild.val(),
                blocks: blocks
            });
          });
        });

        let cRef = Firebase.database().ref('/sessions/'+id+'/'+child.key+'/comments/');
          cRef.once('value', (csnap) => {
            csnap.forEach((cchild) => {
              comments.push({
                id: cchild.key,
                  data: cchild.val(),
              });
            });
          });

        var add = true
        for(var item of global.sessionsTraining) {
          if(item.id === child.key) {
            add = false
          }
        }
        if(add) {
          global.sessionsTraining.push({
            id: child.key,
            data: child.val(),
            program: program,
            comments: comments
          });
        }
        
      });
      EventEmitter.dispatch('trainingLoaded', 'loaded');
    });
  }


  loadChats(callback) {
		const itemsRef = Firebase.database().ref('/messaging/');
    itemsRef.orderByChild("trainer").equalTo(global.uidUser).on('value', (snap) => {
      //global.chats = [];
      snap.forEach((child) => {

      	let cRef = Firebase.database().ref('/messaging/'+child.key+'/messages/');
      	var messages = []
      	cRef.once('value', (csnap) => {
      		csnap.forEach((cchild) => {
            messages.push({
              id: cchild.key,
              data: cchild.val(),
            });
      		});
      	});

        global.chats.push({
          id: child.key,
          data: child.val(),
          messages: messages
        });
      });
      EventEmitter.dispatch('chatsLoaded', 'loaded');
      callback({success: true});
    });
	}


  loadChatsGroup(callback) {
    const itemsRef = Firebase.database().ref('/messagingGroup/'+global.uid);
    itemsRef.on('value', (snap) => {
      global.chatsGroup = [];
      snap.forEach((child) => {

        let cRef = Firebase.database().ref('/messagingGroup/'+global.uid+'/'+child.key+'/clients/');
        var clients = []
        cRef.once('value', (csnap) => {
          csnap.forEach((cchild) => {
            clients.push({
              id: cchild.key,
              data: cchild.val(),
            });
          });
        });

        let sRef = Firebase.database().ref('/messagingGroup/'+global.uid+'/'+child.key+'/staff/');
        var staff = []
        sRef.once('value', (ssnap) => {
          ssnap.forEach((schild) => {
            staff.push({
              id: schild.key,
              data: schild.val(),
            });
          });
        });

        let mRef = Firebase.database().ref('/messagingGroup/'+global.uid+'/'+child.key+'/messages/');
        var messages = []
        mRef.once('value', (msnap) => {
          msnap.forEach((mchild) => {
            messages.push({
              id: mchild.key,
              data: mchild.val(),
            });
          });
        });

        var gdata = child.val()
        if(child.val().name === '' || child.val().name === null || child.val().name === undefined) {
          gdata.name = 'Group chat'
        }
        //if(child.val().name === '' || child.val().name === null || child.val().name === undefined || (global.chatCreate !== child.key && clients.length === 0)) {
        //if(!global.chatCreate && clients.length === 0) {
          //Firebase.database().ref('/messagingGroup/'+global.uidUser+'/'+child.key).remove()
        //} else {
        
        var add = false
        if(HelperBusiness.getRoleIncl('')) {
          add = true
        } else {
          for(var st of staff) {
            if(st.id === global.uidUser) {
              add = true
            }
          }
        }
        if(add) {
          global.chatsGroup.push({
            id: child.key,
            data: gdata,
            clients: clients,
            staff: staff,
            messages: messages
          });
        }
        
      });
      EventEmitter.dispatch('chatsGroupLoaded', 'loaded');
      callback({success: true});
    });
  }


  loadSessions(callback) {
    const itemsRef = Firebase.database().ref('/sessions/'+global.uid);
    itemsRef.on('value', (snap) => {
      if(!global.programLocked && !global.archiveLocked) {
        // get children as an array
        global.sessions = [];
        snap.forEach((child) => {
          let wRef = Firebase.database().ref('/sessions/'+global.uid+'/'+child.key+'/workout/');
          var program = []
          var comments = []
          wRef.once('value', (wsnap) => {
            wsnap.forEach((wchild) => {

              let bRef = Firebase.database().ref('/sessions/'+global.uid+'/'+child.key+'/workout/'+wchild.key+'/blocks/');
              var blocks = []
              bRef.once('value', (bsnap) => {
                bsnap.forEach((bchild) => {
                  blocks.push({
                    id: bchild.key,
                      data: bchild.val(),
                  });
                });
              });

              program.push({
                id: wchild.key,
                  data: wchild.val(),
                  blocks: blocks
              });
            });
          });

          let cRef = Firebase.database().ref('/sessions/'+global.uid+'/'+child.key+'/comments/');
            cRef.once('value', (csnap) => {
              csnap.forEach((cchild) => {
                comments.push({
                  id: cchild.key,
                    data: cchild.val(),
                });
              });
            });

            global.sessions.push({
              id: child.key,
              data: child.val(),
              program: program,
              comments: comments
              //blocks: blocks
            });
            if(child.val().timestamp !== undefined) {
              global.sessions[global.sessions.length-1].data.date = Moment(child.val().timestamp, 'X').format('DD/MM/YYYY HH:mm')
            }
          //}
          
        });
        EventEmitter.dispatch('sessionsLoaded', 'loaded');
        this.checkDuplicates()
      }
      callback({success: true});
    });
	}


  loadArchive(callback) {
    const itemsRef = Firebase.database().ref('/archive/'+global.uid);
    itemsRef.on('value', (snap) => {
      // get children as an array
      if(!global.archiveLocked) {
        global.archive = [];
        snap.forEach((child) => {

          let wRef = Firebase.database().ref('/archive/'+global.uid+'/'+child.key+'/workout/');
          var program = []
          wRef.once('value', (wsnap) => {
            wsnap.forEach((wchild) => {

              let bRef = Firebase.database().ref('/archive/'+global.uid+'/'+child.key+'/workout/'+wchild.key+'/blocks/');
              var blocks = []
              bRef.once('value', (bsnap) => {
                bsnap.forEach((bchild) => {
                  blocks.push({
                    id: bchild.key,
                      data: bchild.val(),
                  });
                });
              });

              program.push({
                id: wchild.key,
                  data: wchild.val(),
                  blocks: blocks
              });
            });
          });

          if(child.val().availability === undefined) {
            global.archive.push({
              id: child.key,
              data: child.val(),
              program: program
            });
          }
          
        });
        if(global.archive.length > 0) {
          EventEmitter.dispatch('archiveLoaded', 'loaded');
        }
      }
      callback({success: true});
    });
  }


  loadTrainingArchive(id) {
    const itemsRef = Firebase.database().ref('/archive/'+id);
    itemsRef.on('value', (snap) => {
      // get children as an array
      snap.forEach((child) => {

        let wRef = Firebase.database().ref('/archive/'+id+'/'+child.key+'/workout/');
        var program = []
        wRef.once('value', (wsnap) => {
          wsnap.forEach((wchild) => {

            let bRef = Firebase.database().ref('/archive/'+id+'/'+child.key+'/workout/'+wchild.key+'/blocks/');
            var blocks = []
            bRef.once('value', (bsnap) => {
              bsnap.forEach((bchild) => {
                blocks.push({
                  id: bchild.key,
                    data: bchild.val(),
                });
              });
            });

            program.push({
              id: wchild.key,
                data: wchild.val(),
                blocks: blocks
            });
          });
        });

        global.archiveTraining.push({
          id: child.key,
          data: child.val(),
          program: program
        });
      });
      EventEmitter.dispatch('archiveTrainingLoaded', 'loaded');
    });
  }


  loadRecurring(callback) {
    const itemsRef = Firebase.database().ref('/recurring/'+global.uid);
    itemsRef.on('value', (snap) => {
      global.recurring = [];
      if(global.userSubStatus === 'active' && global.userSubPlan === 'spark') {
        global.recurring = [];
      } else {
        snap.forEach((child) => {
          global.recurring.push({
            id: child.key,
            data: child.val(),
          });
          if(child.val().timestamp !== undefined) {
            global.recurring[global.recurring.length-1].data.date = Moment(child.val().timestamp, 'X').format('DD/MM/YYYY HH:mm')
          }
        });
        this.checkDuplicates()
      }
      
      EventEmitter.dispatch('recurringLoaded', 'loaded');
      callback({success: true});
    });
  }


  loadEvents(callback) {
		const itemsRef = Firebase.database().ref('/events/'+global.uid);
    itemsRef.on('value', (snap) => {
      global.events = [];
      snap.forEach((child) => {
        global.events.push({
          id: child.key,
          data: child.val(),
        });
      });
      EventEmitter.dispatch('eventsLoaded', 'loaded');
      callback({success: true});
    });
	}


	loadPrograms(callback) {
		const itemsRef = Firebase.database().ref('/workouts/'+global.uid);
    itemsRef.on('value', (snap) => {
      if(!global.programLocked) {
        global.programs = [];
        snap.forEach((child) => {

          let bRef = Firebase.database().ref('/workouts/'+global.uid+'/'+child.key+'/blocks/');
          var blocks = []
          bRef.once('value', (ssnap) => {
            ssnap.forEach((schild) => {
              blocks.push({
                id: schild.key,
                  data: schild.val(),
              });
            });
          });

          if(child.val().name === undefined) {
            Firebase.database().ref('/workouts/'+global.uid+'/'+child.key).remove()
          } else {
            global.programs.push({
              id: child.key,
              data: child.val(),
              blocks: blocks
            });
          }
        });
        this.reduceClientsPrograms()
        EventEmitter.dispatch('programsLoaded', 'loaded');
      }
      callback({success: true}); 
    });
	}


  loadProgramsClient(id) {
    //global.programsClient = [];
		const itemsRef = Firebase.database().ref('/workouts/'+id);
    itemsRef.on('value', (snap) => {
      global.programsClient = [];
      snap.forEach((child) => {
      	let bRef = Firebase.database().ref('/workouts/'+id+'/'+child.key+'/blocks/');
      	var blocks = []
      	bRef.once('value', (ssnap) => {
      		ssnap.forEach((schild) => {
      			blocks.push({
      				id: schild.key,
          			data: schild.val(),
      			});
      		});
      	});

        global.programsClient.push({
          id: child.key,
          data: child.val(),
          blocks: blocks
        });
      });
      EventEmitter.dispatch('clientProgramsLoaded', 'loaded');
    });
    
	}


  loadExercises(callback) {
    //var lockitsch = false
		const itemsRef = Firebase.database().ref('/exercises/');
    //if(!lockitsch) {
		itemsRef.on('value', (snap) => {
			global.exercises = [];
			snap.forEach((child) => {
        if(child.val().user === 'admin' || child.val().user === global.uid) {
  				global.exercises.push({
  					id: child.key,
  					data: child.val(),
					});
				}
        
			});
			EventEmitter.dispatch('exercisesLoaded', 'loaded');
			callback({success: true});
		});
	}


  loadPlans(callback) {
    const itemsRef = Firebase.database().ref('/plans/'+global.uid);
    itemsRef.on('value', (snap) => {
      global.plans = [];
      snap.forEach((child) => {
        let wRef = Firebase.database().ref('/plans/'+global.uid+'/'+child.key+'/weeks/');
        var weeks = []
        wRef.once('value', (ssnap) => {
          ssnap.forEach((wchild) => {
            weeks.push({
              id: wchild.key,
              data: wchild.val(),
            });
          });
        });

        let pRef = Firebase.database().ref('/plans/'+global.uid+'/'+child.key+'/workouts/');
        var programs = []
        pRef.once('value', (psnap) => {
          psnap.forEach((pchild) => {

            let bRef = Firebase.database().ref('/plans/'+global.uid+'/'+child.key+'/workouts/'+pchild.key+'/blocks/');
            var blocks = []
            bRef.once('value', (bsnap) => {
              bsnap.forEach((bchild) => {
                blocks.push({
                  id: bchild.key,
                  data: bchild.val(),
                });
              });
            });

            programs.push({
              id: pchild.key,
              data: pchild.val(),
              blocks: blocks
            });
          });
        });

        if(child.val().name === undefined) {
          Firebase.database().ref('/plans/'+global.uid+'/'+child.key).remove()
        } else {
          global.plans.push({
            id: child.key,
            data: child.val(),
            weeks: weeks,
            programs: programs
          });
        }
      });
      EventEmitter.dispatch('plansLoaded', 'loaded');
      callback({success: true});
    });
  }


	loadPayments(callback) {
		const itemsRef = Firebase.database().ref('/payments/'+global.uid);
    itemsRef.on('value', (snap) => {
      global.payments = [];
      snap.forEach((child) => {
		  global.payments.push({
          id: child.key,
          data: child.val(),
        });
      });
      EventEmitter.dispatch('paymentsLoaded', 'loaded');
      callback({success: true});
    });
	}


  loadPaymentsArchive(callback) {
		const itemsRef = Firebase.database().ref('/paymentsArchive/'+global.uid);
    itemsRef.on('value', (snap) => {
      global.paymentsArchive = [];
      snap.forEach((child) => {
		  global.paymentsArchive.push({
          id: child.key,
          data: child.val(),
        });
      });
      if(global.paymentsArchive.length > 0) {
        EventEmitter.dispatch('paymentsArchiveLoaded', 'loaded');
      }
      callback({success: true});
    });
	}


	loadProducts(callback) {
		const itemsRef = Firebase.database().ref('/packs/'+global.uid);
    itemsRef.on('value', (snap) => {
      global.products = [];
      snap.forEach((child) => {
		  global.products.push({
          id: child.key,
          data: child.val(),
        });
      });
      for(var item of global.products) {
        if(item.data.type === 'sessions') {
          if(item.data.expires === undefined || item.data.expires === 99999) {
            item.data.expires = 12
            item.data.expType = "months"
          }
        }
      }
      EventEmitter.dispatch('productsLoaded', 'loaded');
      callback({success: true});
    });
	}


	loadLog(callback) {
		const itemsRef = Firebase.database().ref('/log/'+global.uid);
    itemsRef.on('value', (snap) => {
      global.userLog = [];
      snap.forEach((child) => {
      	if(child.val().seen === false) {
      		global.userLog.push({
	          id: child.key,
	          data: child.val(),
	        });
	        if(child.val().type === 'charge') {
	        	this.processPayment(child.key, child.val())
	        }
      	}
      });
      EventEmitter.dispatch('logLoaded', 'loaded');
      callback({success: true});
    });
  }


  loadActivity(callback) {
    const itemsRef = Firebase.database().ref('/activity/'+global.uid);
    itemsRef.on('value', (snap) => {
      if(!global.activityLocked) {
        global.activity = [];
        var num = 0;
        snap.forEach((child) => {
          if(Moment(child.val().date, 'DD/MM/YYYY HH:mm') > Moment().add(-14, 'days')) {
            global.activity.push({
              id: child.key,
              data: child.val(),
            });
          } else {
            num++
          }
        });
        EventEmitter.dispatch('activityLoaded', 'loaded');
        if(num > 0) {
          global.activityLocked = true
          this.updateActivity()
        }
        callback({success: true});
      }
    });
  }


  updateActivity() {
    Firebase.database().ref('/activity/'+global.uidUser).remove()
    for(var item of global.activity) {
      Firebase.database().ref('/activity/'+global.uidUser+'/'+item.id).update(
        item.data
      )
    }
    setTimeout(() => {
      global.activityLocked = false
      this.loadActivity((result) => {})
    }, 5000);
  }


  loadClientGroups = async(callback) => {
		const itemsRef = await Firebase.database().ref('/groups/'+global.uid);
    itemsRef.on('value', (snap) => {
      global.clientGroups = [];
      snap.forEach((child) => {
        global.clientGroups.push({
          id: child.key,
          data: child.val(),
        });
      });
      EventEmitter.dispatch('groupsLoaded', 'loaded');
      callback({success: true});
    });
	}


  loadForms(callback) {
    let itemsRef = Firebase.database().ref('/forms/'+global.uid);
    itemsRef.on('value', (snap) => {
      global.forms = [];
      snap.forEach((child) => {
        let sRef = Firebase.database().ref('/forms/'+global.uid+'/'+child.key+'/sections/');
      	var sections = []
      	sRef.once('value', (ssnap) => {
      		ssnap.forEach((schild) => {
      			sections.push({
      				id: schild.key,
          		data: schild.val(),
      			});
      		});
      	});

        global.forms.push({
          id: child.key,
          data: child.val(),
          sections: sections,
          history: []
        });
      });
      EventEmitter.dispatch('formsLoaded', 'loaded');
      callback({success: true});
    });
  }


  loadEmails(callback) {
    const itemsRef = Firebase.database().ref('/emails/'+global.uid);
    itemsRef.on('value', (snap) => {
      global.emails = [];
      snap.forEach((child) => {
        global.emails.push({
          id: child.key,
          data: child.val(),
        });
      });
      EventEmitter.dispatch('emailsLoaded', 'loaded');
      callback({success: true});
    });
	}


  loadLocations(callback) {
		const itemsRef = Firebase.database().ref('/locations/'+global.uid);
    itemsRef.on('value', (snap) => {
      global.locations = [];
      global.spaceLocations = [];
      snap.forEach((child) => {
        global.locations.push({
          id: child.key,
          data: child.val(),
        });
        global.spaceLocations.push({
          id: child.key,
          data: child.val(),
        });
      });
      EventEmitter.dispatch('locationsLoaded', 'loaded');
      callback({success: true});
    });
	}


  processPayment(id, data) {
    Firebase.database().ref('/log/'+global.uid+'/'+id).remove()
  }


	loadUser(callback) {
		let itemsRef = Firebase.database().ref('/users/'+global.uidUser);
		itemsRef.on("value", function(snapshot) {
			if (snapshot.exists()) {
				var data = snapshot.val();
				global.userBday = Moment(data.birth, 'DD/MM/YYYY')
		  	global.userPhone = data.phone
        global.userEmail = data.email
        global.userName = data.name
        global.userRole = ''
		  	global.userImage = ''
		  	global.userImageDate = Moment(data.imageDate, 'DD/MM/YYYY HH:mm')
        global.userMessage = data.message
				global.timerStart = data.timer ?? 5
				global.userTemplates = data.templates
				global.userEmailsSent = data.emails
				global.userImage = data.image
        global.userVerified = data.verified
        global.coupon = data.coupon
        global.userUser = data.user
        global.userCountry = data.country
        global.userLbs = false
        var ob = data.showOnboarding
        global.userSessionReminder = 99999
        if(data.reminder !== undefined) {
          global.userSessionReminder = data.reminder
        }

        if(data.badges !== undefined) {
          global.userBadges = data.badges
        }
        if(ob !== undefined) {
          global.userOnboarding = ob
        }

        if((global.userOnboarding === undefined || (global.userOnboarding.length === 2 && global.userOnboarding[0] === '')) && global.clientsLoaded) {
          global.showOnboarding = true
        }

        if(data.types !== undefined) {
          global.userTypes = data.types
        }
        if(data.experience !== undefined) {
          global.userExperience = data.experience
        }

        if(data.activityTypes !== undefined) {
          global.activityTypes = data.activityTypes
        }
        if(data.lbs !== undefined) {
          global.userLbs = data.lbs
        }

        // Pricing
        if(global.userCountry === 'au') {
          global.prices = global.pricesAU
          global.userCurrency = "aud"
          global.curSym = "A$"
          global.stripeFee = '2.25% + A$0.30'
        } else {
          global.prices = global.pricesUS
          global.userCurrency = "usd"
          global.curSym = "$"
          global.stripeFee = '3.4% + $0.30'
        }

        if(data.imageDate !== undefined) {
          if(Moment().format('DD/MM/YYYY') !== Moment(data.imageDate, 'DD/MM/YYYY HH:mm').format('DD/MM/YYYY')) {
            global.showOverview = true
          }
        }

        global.affiliates = []
        if(data.affiliates !== undefined) {
          global.affiliates = data.affiliates
        }

        global.currentLocation = ''
        if(data.location !== undefined) {
          global.currentLocation = data.location
        }

        EventEmitter.dispatch('userDataLoaded', 'loaded');
        callback({success: true});
			} else {
				global.userPhone = ''
			}
      if(global.userPhone === '') {
        window.location.pathname = '/complete-signup'
      } else {
        if(data.image !== '') {
          Firebase.storage().ref().child(data.image).getDownloadURL().then((url) => {
            global.userImage = url
            EventEmitter.dispatch('userLoaded', 'loaded');
          }).catch((error) => {
            // Handle any errors
          })
        }
        EventEmitter.dispatch('userLoaded', 'loaded');
        callback({success: true}); 
      }
    
		});
    
    // Load from spaces
		/*let cRef = Firebase.database().ref('/users/'+global.uidUser+'/cards/');
  	global.cards = []
  	cRef.once('value', (csnap) => {
  		csnap.forEach((cchild) => {
  			global.cards.push({
  				id: cchild.key,
      			data: cchild.val(),
  			});
  		});
  	});*/
	}


  loadUserSpace(callback) {
    let itemsRef = Firebase.database().ref('/spaces/'+global.uidUser);
		itemsRef.on("value", function(snapshot) {
			if (snapshot.exists()) {
				var data = snapshot.val();
        global.userCommunity = data.community
        global.userCommunityPost = data.communityPost
        global.userComments = data.comments
        global.userShowBooked = data.showBooked

        global.userBusiness = data.name
        global.userStripe = data.stripe
        global.userCard = data.card
		  	global.userSubPlanId = data.subPlanId
		  	global.userSubId = data.subId
		  	global.userSubStatus = data.subStatus
		  	global.userSubBilling = data.subBilling
		  	global.userSubCancel = data.subCancel
		  	global.userSubEnd = data.subEnd
		  	global.subItemId = data.subItemId
		  	global.userSubItemId = data.subItemId
        global.spaceImage = data.image
        global.userStripeConnect = data.stripeConnect
				global.userStripeBank = data.stripeConnectBank
				global.spaceAddress = data.address
        global.spacePin = data.pin
        global.spaceTheme = data.theme
        global.spaceEmail = data.email
        global.spacePhone = data.phone
        global.spaceEmailReminder = data.emailReminder ?? false

        if(data.verified !== undefined) {
          global.userVerified = data.verified
        }

        if(data.chargeSessions !== undefined) {
          global.userChargeSessions = data.chargeSessions
        }

        global.spaceAllowRecurring = false
        if(data.allowRecurring !== undefined) {
          global.spaceAllowRecurring = data.allowRecurring
        }

        global.spaceLbs = false
        if(data.lbs !== undefined) {
          global.spaceLbs = data.lbs
        }

        global.spaceGST = 0
        if(data.gst !== undefined) {
          global.spaceGST = data.gst
        }

        global.spaceEmailPacks = false
        if(data.emailPacks !== undefined) {
          global.spaceEmailPacks = data.emailPacks
        }

        global.spaceNoshow = 'session'
        global.spaceNoshowFee = 0
        global.spaceInvoice = 1
        global.spaceInvoice1 = ""
        global.spaceInvoice2 = ""
        if(data.noshow !== undefined) {
          global.spaceNoshow = data.noshow
        }
        if(data.noshowFee !== undefined) {
          global.spaceNoshowFee = data.noshowFee
        }
        if(data.invoice !== undefined) {
          global.spaceInvoice = data.invoice
        }
        if(data.invoice1 !== undefined) {
          global.spaceInvoice1 = data.invoice1
        }
        if(data.invoice2 !== undefined) {
          global.spaceInvoice2 = data.invoice2
        }

        global.spaceWelcome = ''
        global.spaceWelcomeTime = 0
        if(data.welcome !== undefined) {
          global.spaceWelcome = data.welcome
          global.spaceWelcomeTime = data.welcomeTime
        }

        //Nutrition
        if(data.nutritionId !== undefined) {
          global.userNutritionId = data.nutritionId
          global.userNutritionGym = data.nutritionGym
          global.userNutritionDate = data.nutritionDate
        } else {
          global.userNutritionId = ""
        }
        if(data.nutritionTrial !== undefined) {
          global.userNutritionTrial = data.nutritionTrial
        }

        if(data.syncDate !== undefined) {
          global.syncDate = data.syncDate
        }

        global.userLimitBooking = false
        if(data.limitBooking !== undefined) {
          global.userLimitBooking = data.limitBooking
        }

        if(data.preExercise !== undefined) {
          global.spacePreEx = data.preExercise
        }

        if(data.reminder !== undefined) {
          global.userReminder = data.reminder
        }

        if(data.allowBooking !== undefined) {
          global.userAllowBooking = data.allowBooking
        }

        global.userSubdomain = ''
        if(data.subdomain !== undefined) {
          global.userSubdomain = data.subdomain
        }

        if(data.image !== '') {
          Firebase.storage().ref().child(data.image).getDownloadURL().then((url) => {
            global.spaceImage = url
            EventEmitter.dispatch('userLoaded', 'loaded');
          }).catch((error) => {
            // Handle any errors
          })
        }

        for(var theme of global.themes) {
          if(theme.id === global.spaceTheme) {
            global.themeColors = theme.colors
            global.emptyColors = theme.empty
          }
        }

        global.userDataLoaded = true;

        EventEmitter.dispatch('userLoaded', 'loaded');
        callback({success: true}); 
      }
    });

    let cRef = Firebase.database().ref('/spaces/'+global.uidUser+'/cards/');
  	global.cards = []
  	cRef.once('value', (csnap) => {
  		csnap.forEach((cchild) => {
  			global.cards.push({
  				id: cchild.key,
      			data: cchild.val(),
  			});
  		});
  	});
  }


  loadStaff() {
    let itemsRef = Firebase.database().ref('/spaces/'+global.uid+'/staff');
		itemsRef.on('value', (snap) => {
      global.userStaff = [];
      snap.forEach((child) => {
        global.userStaff.push({
          id: child.key,
          data: child.val()
        });
      });
      if(global.uid === global.uidUser) {
        if(global.userStaff.length > 0 && global.userSubStatus !== 'trialing') {
          var plan = 'spark'
          for(var item of global.stripePlans) {
            if(item.plan === global.userSubPlanId) {
              plan = item.type
            }
          }
          if(plan === 'spark' || plan === 'foundmonthly' || plan === 'foundyearly') {
            for(var st1 of global.userStaff) {
              Firebase.database().ref('/spaces/'+global.uid+'/staff/'+st1.id).update({
                status: 'inactive',
              })
              if(st1.data.role === 'assistant2' || st1.data.role === 'trainer2') {
                Firebase.database().ref('/users/'+st1.id+'/spaces/'+global.uidUser).update({
                  status: 'inactive',
                })
              } else {
                Firebase.database().ref('/usersStaff/'+st1.id).update({
                  status: 'inactive',
                })
              }
              
            }
          } else if((plan === 'basicyearly' || plan === 'basicmonthly') && global.userStaff.length > 1) {
            for(var i=1; i<global.userStaff.length; i++) {
              Firebase.database().ref('/spaces/'+global.uid+'/staff/'+global.userStaff[i].id).update({
                status: 'inactive',
              })
              if(global.userStaff[i].data.role === 'assistant2' || global.userStaff[i].data.role === 'trainer2') {
                Firebase.database().ref('/users/'+global.userStaff[i].id+'/spaces/'+global.uidUser).update({
                  status: 'inactive',
                })
              } else {
                Firebase.database().ref('/usersStaff/'+global.userStaff[i].id).update({
                  status: 'inactive',
                })
              }
            }
          } else if((plan === 'proyearly' || plan === 'promonthly') && global.userStaff.length > 0) {
            for(var st2 of global.userStaff) {
              if(st2.data.role !== 'assistant' && st2.data.role !== 'assistant2') {
                Firebase.database().ref('/usersStaff/'+st2.id).update({
                  status: 'inactive',
                })
                Firebase.database().ref('/spaces/'+global.uid+'/staff/'+st2.id).update({
                  status: 'inactive',
                })
              }
            }
          }
        }
      }
      EventEmitter.dispatch('staffLoaded', 'loaded');

    });
    
  }


  loadUserStaff(callback) {
    global.currentWeek = parseInt(Moment().format('w'));
    let itemsRef = Firebase.database().ref('/usersStaff/'+global.uidUser);
		itemsRef.on("value", function(snapshot) {
			if (snapshot.exists()) {
        var data = snapshot.val();
        global.userBday = Moment(data.birth, 'DD/MM/YYYY')
		  	global.userPhone = data.phone
        global.userEmail = data.email
        global.userName = data.name
        global.userRole = data.role
		  	global.userImage = ''
		  	global.userImageDate = Moment(data.imageDate, 'DD/MM/YYYY HH:mm')
				global.userImage = data.image
        global.userCountry = data.country
        global.uid = data.space
        global.userStaffStatus = data.status

        global.currentAssistant = global.uid
        global.userSessionReminder = 99999
        if(data.reminder !== undefined) {
          global.userSessionReminder = data.reminder
        }

        global.userLbs = false
        if(data.lbs !== undefined) {
          global.userLbs = data.lbs
        }

        if(data.image !== '') {
          Firebase.storage().ref().child(data.image).getDownloadURL().then((url) => {
            global.spaceImage = url
            EventEmitter.dispatch('userLoaded', 'loaded');
          }).catch((error) => {
            // Handle any errors
          })
        }
        global.showOverview = false

        EventEmitter.dispatch('userLoaded', 'loaded');
        callback({success: true}); 
      }
    });
  }


  loadStaffSpace(callback) {
    let itemsRef = Firebase.database().ref('/spaces/'+global.uid);
		itemsRef.on("value", function(snapshot) {
			if (snapshot.exists()) {
        var data = snapshot.val();
        global.userCommunity = data.community
        global.userCommunityPost = data.communityPost
        global.userComments = data.comments
        global.userShowBooked = data.showBooked
        global.userBusiness = data.name
        global.spaceImage = data.image
        global.userVerified = data.verified
        global.userStripeConnect = data.stripeConnect
				global.userStripeBank = data.stripeConnectBank
				global.spaceAddress = data.address
        global.spacePin = data.pin
        global.spaceTheme = data.theme
        global.spaceEmail = data.email
        global.spacePhone = data.phone
        global.spaceOwner = data.owner
        global.spaceEmailReminder = data.emailReminder ?? false

        global.userSubPlanId = data.subPlanId
		  	global.userSubStatus = data.subStatus

        global.spaceLbs = false
        if(data.lbs !== undefined) {
          global.spaceLbs = data.lbs
        }

        global.spaceGST = 0
        if(data.gst !== undefined) {
          global.spaceGST = data.gst
        }

        global.spaceNoshow = 'session'
        global.spaceNoshowFee = 0
        global.spaceInvoice = 1
        global.spaceInvoice1 = ""
        global.spaceInvoice2 = ""
        if(data.noshow !== undefined) {
          global.spaceNoshow = data.noshow
        }
        if(data.noshowFee !== undefined) {
          global.spaceNoshowFee = data.noshowFee
        }
        if(data.invoice !== undefined) {
          global.spaceInvoice = data.invoice
        }
        if(data.invoice1 !== undefined) {
          global.spaceInvoice1 = data.invoice1
        }
        if(data.invoice2 !== undefined) {
          global.spaceInvoice2 = data.invoice2
        }

        global.spaceWelcome = ''
        global.spaceWelcomeTime = 0
        if(data.welcome !== undefined) {
          global.spaceWelcome = data.welcome
          global.spaceWelcomeTime = data.welcomeTime
        }

        //Nutrition
        if(data.nutritionId !== undefined) {
          global.userNutritionId = data.nutritionId
          global.userNutritionGym = data.nutritionGym
          global.userNutritionDate = data.nutritionDate
        } else {
          global.userNutritionId = ""
        }

        global.userCurrency = "aud"
        global.curSym = "A$"
        global.stripeFee = '2.25% + A$0.30'
        if(data.country === 'us') {
          global.userCurrency = "usd"
          global.curSym = "$"
          global.stripeFee = '3.4% + $0.30'
        }

        if(data.image !== '') {
          Firebase.storage().ref().child(data.image).getDownloadURL().then((url) => {
            global.spaceImage = url
            EventEmitter.dispatch('userLoaded', 'loaded');
          }).catch((error) => {
            // Handle any errors
          })
        }

        global.userDataLoaded = true;

        EventEmitter.dispatch('userLoaded', 'loaded');
        callback({success: true}); 
      }
    });
  }


	loadStripePlans(callback) {
    EventEmitter.dispatch('plansLoaded', 'loaded');
    callback({success: true});
    this.checkUserSubscription()
	}


  checkUserSubscription() {
    for(var item of global.stripePlans) {
      if(item.plan === global.userSubPlanId) {
        global.userSubPlan = item.type
      }
    }

    if(global.userSubStatus === 'unpaid' || global.userSubStatus === 'canceled') {
      global.userSubPlan = 'spark'
    }

    global.maxClients = 0
    global.maxPrograms = 0
    global.maxPlans = 0
    global.maxTrainers = 0
    global.showHabits = false
    var communityOff = false
    if(global.userDataLoaded) {
      if(global.userSubPlan === 'spark' && global.userSubStatus !== 'trialing' && global.userRole === '') {
        global.maxClients = 2
        global.maxPrograms = 2
        global.maxPlans = 0
        global.maxTrainers = 0
        communityOff = true
        this.reduceClientsPrograms()
      }
      if((global.userSubPlan === 'foundmonthly' || global.userSubPlan === 'foundyearly') && global.userSubStatus !== 'trialing' && global.userRole === '') {
        global.maxClients = 10
        global.maxPrograms = 40
        global.maxPlans = 0
        global.maxTrainers = 0
        communityOff = true
        this.reduceClientsPrograms()
      }
      if((global.userSubPlan === 'basicmonthly' || global.userSubPlan === 'basicyearly') && global.userSubStatus !== 'trialing' && global.userRole === '') {
        global.maxTrainers = 1
        global.maxPlans = 0
        communityOff = true
      }

      if((global.userSubPlan === 'promonthly' || global.userSubPlan === 'proyearly') || global.userSubStatus === 'trialing') {
        global.maxTrainers = 99999
        global.maxPlans = 99999
        global.maxClients = 0
        global.maxPrograms = 0
        communityOff = false
      }

      if(global.userSubPlan === 'businessmonthly' || global.userSubPlan === 'businessyearly' || global.userSubStatus === 'trialing') {
        global.maxTrainers = 99999
        global.maxPlans = 99999
        global.maxClients = 0
        global.maxPrograms = 0
        communityOff = false
        global.showHabits = true
      }

      if((global.userSubStatus === 'cancelled' || global.userSubStatus === 'canceled' || global.userSubStatus === 'incomplete_expired') && global.userRole === '') {
        global.maxClients = 2
        global.maxPrograms = 2
        global.maxPlans = 0
        global.maxTrainers = 0
        communityOff = true
        global.showHabits = false
      }


      if(communityOff && global.userRole === '') {
        if(global.userCommunity) {
          /*Firebase.database().ref('/users/'+global.uidUser).update({
            community: false
          })*/
          Firebase.database().ref('/spaces/'+global.uidUser).update({
            community: false
          })
        }
        if(global.spaceTheme !== 'blue') {
          Firebase.database().ref('/spaces/'+global.uidUser).update({
            theme: 'blue'
          })
        }
        this.checkStaff()
      }
      
      EventEmitter.dispatch('userLoaded', 'loaded');
    }
  }


  checkStaff() {
    if(global.userRole === '') {
      var lock = false
      var num = 0
      var plan = 'spark'
      for(var itemp of global.stripePlans) {
        if(itemp.plan === global.userSubPlanId) {
          plan = itemp.type
        }
      }

      if(lock) {
        for(var item of global.userStaff) {
          num++
          if(global.userRole !== '' && global.userSubStatus !== 'trialing') {
            if(plan === 'spark' || plan === 'foundmonthly' || plan === 'foundyearly') {
              lock = true
            }
            if(plan !== 'businessmonthly' && plan !== 'businessyearly' && item.data.role !== 'assistant' && item.data.role !== 'assistant2') {
              lock = true
            }
            if((plan === 'basicmonthly' || plan === 'basicyearly') && num > 1) {
              lock = true
            }
          }
          if(lock) {
            Firebase.database().ref('/spaces/'+global.uidUser+'/staff/'+item.id).update({
              status: 'inactive'
            })
            if(item.data.role === 'assistant2' || item.data.role !== 'trainer2') {
              Firebase.database().ref('/users/'+item.id+'/spaces/'+global.uidUser).update({
                status: 'inactive'
              })
            } else {
              Firebase.database().ref('/usersStaff/'+item.id).update({
                status: 'inactive'
              })
            }
          }
        }
      }
    }
  }


  checkStaffActive() {
    if(global.userRole !== '') {
      var lock = false
      var plan = 'spark'
      for(var item of global.stripePlans) {
        if(item.plan === global.userSubPlanId) {
          plan = item.type
        }
      }
      if(global.userRole !== '' && global.userSubStatus !== 'trialing') {
        if(plan === 'spark' || plan === 'foundmonthly' || plan === 'foundyearly') {
          lock = true
        }
        if(plan !== 'businessmonthly' && plan !== 'businessyearly' && global.userRole !== 'assistant' && global.userRole !== 'assistant2') {
          lock = true
        }
      }
      if(lock) {
        Firebase.database().ref('/usersStaff/'+global.uidUser).update({
          status: 'inactive'
        })
        Firebase.database().ref('/spaces/'+global.uid+'/staff/'+global.uidUser).update({
          status: 'inactive'
        })
      }
    }
  }


  loadUserAssistant() {
    let itemsRef = Firebase.database().ref('/users/'+global.uidUser+'/assistant');
    itemsRef.on('value', (snap) => {
      global.userAssistant = [];
      snap.forEach((child) => {
        global.userAssistant.push({
          id: child.key,
          data: child.val()
        });
      });
      var exists = false
      for(var item of global.userAssistant) {
        if(item.id === global.currentAssistant) {
          exists = true
        }
      }
      if(!exists && global.currentAssistant !== '') {
        global.currentAssistant = ''
        global.userRole = ''
        Firebase.database().ref('/users/'+global.uidUser).update({
          user: ''
        })
        this.loadClients((result) => {})
        this.loadSessions((result) => {})
        this.loadRecurring((result) => {})
        this.loadPrograms((result) => {})
        this.loadExercises((result) => {})
        this.loadEvents((result) => {})
        EventEmitter.dispatch('assistantUpdated', 'loaded');
      }
      EventEmitter.dispatch('staffLoaded', 'loaded');
    });
  }


  loadUserSpaces() {
    let itemsRef = Firebase.database().ref('/users/'+global.uidUser+'/spaces');
    itemsRef.on('value', (snap) => {
      global.userSpaces = [];
      snap.forEach((child) => {
        if(child.val().status === 'active') {
          global.userSpaces.push({
            id: child.key,
            data: child.val()
          });
        }
        
      });
      var exists = false
      for(var item of global.userSpaces) {
        if(item.id === global.uid) {
          exists = true
          global.userRole = item.data.role
          if(item.data.status !== 'active') {
            exists = false
          }
        }
      }
      if(!exists && global.userUser !== '') {
        global.userUser = ''
        global.uid = global.uidUser
        global.userRole = ''
        Firebase.database().ref('/users/'+global.uidUser).update({
          user: ''
        })
        window.location.pathname = '/overview'
      }
      EventEmitter.dispatch('staffLoaded', 'loaded');
    });
  }


  loadSpaceImage() {
    let itemsRef = Firebase.database().ref('/spaces/'+global.uid);
		itemsRef.on("value", function(snapshot) {
			if (snapshot.exists()) {
        var data = snapshot.val();
        global.spaceImage = data.image
        global.userBusiness = data.name

        if(data.image !== '') {
          Firebase.storage().ref().child(data.image).getDownloadURL().then((url) => {
            global.spaceImage = url
            EventEmitter.dispatch('userLoaded', 'loaded');
          }).catch((error) => {
            // Handle any errors
          })
        }

        EventEmitter.dispatch('userLoaded', 'loaded');
      }
    });
  }


	loadClientBest(id) {
		global.clientBest = []
		let bRef = Firebase.database().ref('/records/'+id);
  	bRef.on('value', (bsnap) => {
  		bsnap.forEach((bchild) => {
        //if(bchild.val().value !== 0) {
          global.clientBest.push({
            id: bchild.key,
            data: bchild.val(),
          });
        //}
  		});
  		EventEmitter.dispatch('bestLoaded', 'loaded');
  	});
    
	}


  loadClientBestGroup(id) {
		let bRef = Firebase.database().ref('/records/'+id);
  	bRef.once('value', (bsnap) => {
  		bsnap.forEach((bchild) => {
  			global.clientBestGroup.push({
          client: id,
  				id: bchild.key,
      		data: bchild.val(),
  			});
  		});
  	});
	}


  loadSchedule(callback) {
		const itemsRef = Firebase.database().ref('/schedule/');
    itemsRef.on('value', (snap) => {
      global.schedule = [];
      snap.forEach((child) => {
        global.schedule.push({
          id: child.key,
          data: child.val(),
        });
      });
      EventEmitter.dispatch('scheduleLoaded', 'loaded');
      callback({success: true});
    });
	}


  reduceClientsPrograms() {
    if(global.clients.length > global.maxClients && global.maxClients !== 0 && global.uid === global.uidUser) {
      for(var i=global.maxClients-1; i<global.clients.length-1; i++) {
        var item = global.clients[i]
        if(item.data.active || item.data.active === undefined) {
          Firebase.database().ref('/clients/'+global.uidUser+'/'+item.id).update({
            active: false
          })
        }
      }
    }
    if(global.programs.length > global.maxPrograms && global.maxPrograms !== 0) {
      var tmp2 = []
      var num2 = 0
      for(var program of global.programs) {
        if(num2 < global.maxPrograms) {
          tmp2.push(program)
        }
        num2++
      }
      global.programs = tmp2
    }
    EventEmitter.dispatch('clientsLoaded', 'loaded');
    EventEmitter.dispatch('programsLoaded', 'loaded');
  }


  async checkRecurring() {
    await setTimeout(() => {
      if(!global.updatingRec) {
        global.updatingRec = true
        var date = parseInt(Moment().add(90, 'days').format('X'))
        for(var item of global.recurring) {
          var num = item.data.current
          var idate = parseInt(Moment(item.data.date, 'DD/MM/YYYY HH:mm').add((num*item.data.interval), 'days').format('X'))
          var edate = parseInt(Moment('01/01/3000', 'DD/MM/YYYY').format('X'))
          if(item.data.dateEnd !== undefined) {
            edate = parseInt(item.data.dateEnd)
          }
          if(item.data.timestamp !== undefined) {
            idate = parseInt(Moment(item.data.timestamp, 'X').add((num*item.data.interval), 'days').format('X'))
          }
          if(idate < date) {
            //global.programLocked = true
            var tosave = num
            for(var i=num; i<num+20; i++) {
              //var dt = parseInt(Moment(item.data.date, 'DD/MM/YYYY HH:mm').add((i*item.data.interval), 'days').format('X'))
              var dt = parseInt(Moment(item.data.date, 'DD/MM/YYYY HH:mm').add((i*item.data.interval), 'days').format('X'))
              if(item.data.timestamp !== undefined) {
                dt = parseInt(Moment(item.data.timestamp, 'X').add((i*item.data.interval), 'days').format('X'))
              }
              if(dt < date && dt < edate) {
                tosave = i
                if(item.data.type === 'event') {
                  this.createSessionsEvent(item, i)
                } else {
                  if(item.data.availability) {
                    this.createSessionsAvail(item, i)
                  } else {
                    this.createSessions(item, i)
                  }
                }
                
              }
            }
            Firebase.database().ref('/recurring/'+global.uid+'/'+item.id).update({
              current: (tosave+1)
            })
          }
        }
      }
    }, 10000);
    setTimeout(() => {
      global.updatingRec = false
    }, 30000);
  }


  createSessions(item, num) {
    var plan = true
    

    //var endDate = parseInt(Moment().add(90, 'days').format('X'))
    //var date = parseInt(Moment(item.data.date, 'DD/MM/YYYY HH:mm').add((num*item.data.interval), 'days').format('X'))
    //if(date < endDate) {
      var ts = parseInt(Moment(item.data.date, 'DD/MM/YYYY HH:mm').add((num*item.data.interval), 'days').format('X'))
      if(item.data.timestamp !== undefined) {
        ts = parseInt(Moment(item.data.timestamp, 'X').add((num*item.data.interval), 'days').format('X'))
      }
      var invitees = [];
      if(item.data.invitees !== undefined) {
        invitees = item.data.invitees;
      }
      var groups = [];
      if(item.data.groups !== undefined) {
        groups = item.data.groups;
      }
      var memberships = [];
      if(item.data.memberships !== undefined) {
        memberships = item.data.memberships;
      }
      var location = null
      var locationName = null
      if(item.data.location !== undefined) {
        location = item.data.location;
        locationName = item.data.locationName;
      }
      var lock = parseInt(Moment(ts, 'X').add(10000, 'hours').format('X'))
      if(item.data.locked !== undefined && item.data.locked !== -10000 && item.data.locked !== 10000) {
        lock = parseInt(Moment(ts, 'X').add(item.data.locked, 'hours').format('X'))
      }
      var days = 9999
      if(item.data.unlocked !== undefined) {
        days = item.data.unlocked
      }
      var link = ''
      if(item.data.link !== undefined) {
        link = item.data.link
      }
      var tid = ''
      var tname= ''
      if(item.data.trainerId !== undefined) {
        tid = item.data.trainerId
        tname = item.data.trainerName
      }
      var max = 0;
      if(item.data.max !== undefined) {
        max = item.data.max;
      }
      var sname = ''
      if(item.data.name !== undefined) {
        sname = item.data.name
      }
      var desc = ''
      if(item.data.desc !== undefined) {
        desc = item.data.desc
      }
      var clients = []
      var bookings = []
      if(item.data.clients !== undefined) {
        clients = item.data.clients
        for(var cl of item.data.clients) {
          bookings.push(cl+"||booking||recurring||"+Moment(item.data.date, 'DD/MM/YYYY HH:mm').format('X'))
        }
      }
      var unlocked = Moment(ts, 'X').add(-days, 'days').format('DD/MM/YYYY HH:mm')
      var obj = Firebase.database().ref('/sessions/'+global.uid).push()
      obj.update({
        client: item.data.client,
        group: item.data.group,
        duration: item.data.duration,
        preview: item.data.preview,
        date: Moment(item.data.date, 'DD/MM/YYYY HH:mm').add((num*item.data.interval), 'days').format('DD/MM/YYYY HH:mm'),
        timestamp: ts,
        notes: '',
        name: sname,
        desc: desc,
        clients: clients,
        attendance: 2,
        plan: plan,
        invitees: invitees,
        groups: groups,
        memberships: memberships,
        uid: global.uid,
        template: item.id,
        locked: lock,
        unlocked: unlocked,
        templateSeq: num,
        trainerId: tid,
        trainerName: tname,
        link: link,
        max: max,
        location: location,
        locationName: locationName,
        bookings: bookings,
      }).then(() => {
        //this.createSessions(item, num+1)
      }).catch((error)=>{
        EventEmitter.dispatch('showMessageError', error.message);
        this.setState({loading: false})
      })
      // Scheduled reminders
      var scheduled = Moment(ts, 'X').add(-global.userSessionReminder, 'hours').format('X')
      var tokens = []
      var emails = []
      if(item.data.group) {
        var name = "Class"
        if(item.data.client !== '') {
          name = item.data.client
        }
        if(clients.length > 0) {
          for(var cl1 of global.clients) {
            if(clients.indexOf(cl1.id) !== -1 && cl1.data.pushToken !== undefined && cl1.data.pushToken !== '') {
              tokens.push(cl1.data.pushToken)
            }
            if(clients.indexOf(cl1.id) !== -1 && cl1.data.email !== '' && (cl1.data.emailReminder === undefined || cl1.data.emailReminder)) {
              emails.push(cl1.data.email)
            }
          }
        }
        /*if(tokens.length > 0) {
          Firebase.database().ref('/schedule/'+obj.key+'-push').update({
            title: "Session Reminder",
            desc: "You are booked in for "+name+" "+Cal.getSpecialDatePush(ts, scheduled)+".",
            type: "",
            timestamp: parseInt(Moment(ts, 'X').add(-global.userSessionReminder, 'hours').format('X')),
            tokens: [tokens],
            message: "push",
            uid: global.uid,
            iid: obj.key
          })
        }
        if(emails.length > 0 && global.spaceEmailReminder) {
          Firebase.database().ref('/schedule/'+obj.key+'-email').update({
            title: global.userBusiness,
            desc: name,
            type: Cal.getSpecialDatePush(ts, scheduled),
            timestamp: parseInt(Moment(ts, 'X').add(-global.userSessionReminder, 'hours').format('X')),
            tokens: emails,
            message: "sessionemail",
            uid: global.uid,
            iid: global.uid
          })
        }*/
      } else {
        for(var cl2 of global.clients) {
          if(cl2.id === item.data.client) {
            if(cl2.data.pushToken !== undefined && cl2.data.pushToken !== '') {
              Firebase.database().ref('/schedule/'+obj.key+'-push').update({
                title: "Session Reminder",
                desc: "You are booked in for a PT session "+Cal.getSpecialDatePush(ts, scheduled)+".",
                type: "",
                timestamp: parseInt(Moment(ts, 'X').add(-global.userSessionReminder, 'hours').format('X')),
                tokens: [cl2.data.pushToken],
                message: "push",
                uid: global.uid,
                iid: obj.key
              })
            }
            if(cl2.data.email !== '' && global.spaceEmailReminder && (cl2.data.emailReminder === undefined || cl2.data.emailReminder)) {
              Firebase.database().ref('/schedule/'+obj.key+'-email').update({
                title: global.userBusiness,
                desc: "PT session",
                type: Cal.getSpecialDatePush(ts, scheduled),
                timestamp: parseInt(Moment(ts, 'X').add(-global.userSessionReminder, 'hours').format('X')),
                tokens: emails,
                message: "sessionemail",
                uid: global.uid,
                iid: global.uid
              })
            }
          }
        }
      }
    /*} else {
      Firebase.database().ref('/recurring/'+global.uid+'/'+item.id).update({
        current: num
      })
    }*/
    
  }


  createSessionsAvail(item, num) {
    var plan = false
    if(global.userStripeBank == null) {
      plan = true
    }
    var ts = parseInt(Moment(item.data.date, 'DD/MM/YYYY HH:mm').add((num*item.data.interval), 'days').format('X'))
    if(item.data.timestamp !== undefined) {
      ts = parseInt(Moment(item.data.timestamp, 'X').add((num*item.data.interval), 'days').format('X'))
    }
    var lock = 10000
    if(item.data.locked !== undefined) {
      lock = item.data.locked
    }
    var locked = parseInt(Moment(ts, 'X').add(lock, 'hours').format('X'))
    var days = 9999
    if(item.data.unlocked !== undefined) {
      days = item.data.unlocked
    }
    var link = ''
    if(item.data.link !== undefined) {
      link = item.data.link
    }
    var tid = ''
    var tname= ''
    if(item.data.trainerId !== undefined) {
      tid = item.data.trainerId
      tname = item.data.trainerName
    }
    var location = null
    var locationName = null
    if(item.data.location !== undefined) {
      location = item.data.location;
      locationName = item.data.locationName;
    }
    var unlocked = Moment(ts, 'X').add(-days, 'days').format('DD/MM/YYYY HH:mm')
    Firebase.database().ref('/sessions/'+global.uid).push({
      client: '',
      availability: true,
      group: item.data.group,
      duration: item.data.duration,
      preview: item.data.preview,
      date: Moment(item.data.date, 'DD/MM/YYYY HH:mm').add((num*item.data.interval), 'days').format('DD/MM/YYYY HH:mm'),
      timestamp: ts,
      notes: '',
      attendance: 2,
      max: 1,
      plan: plan,
      invitees: [],
      groups: [],
      uid: global.uid,
      template: item.id,
      locked: locked,
      unlocked: unlocked,
      templateSeq: num,
      trainerId: tid,
      trainerName: tname,
      link: link,
      location: location,
      locationName: locationName
    }).then(() => {
      //this.createSessions(item, num+1)
    }).catch((error)=>{
      EventEmitter.dispatch('showMessageError', error.message);
      this.setState({loading: false})
    })
  }


  createSessionsEvent(item, num) {
    var ts = parseInt(Moment(item.data.date, 'DD/MM/YYYY HH:mm').add((num*item.data.interval), 'days').format('X'))
    if(item.data.timestamp !== undefined) {
      ts = parseInt(Moment(item.data.timestamp, 'X').add((num*item.data.interval), 'days').format('X'))
    }
    var link = ''
    if(item.data.link !== undefined) {
      link = item.data.link
    }
    var priv = false
    if(item.data.private !== undefined) {
      priv = item.data.private
    }
    var max = 0;
    if(item.data.max !== undefined) {
      max = item.data.max;
    }
    var desc = ''
    if(item.data.desc !== undefined) {
      desc = item.data.desc
    }
    var invitees = [];
    if(item.data.invitees !== undefined) {
      invitees = item.data.invitees;
    }
    var groups = [];
    if(item.data.groups !== undefined) {
      groups = item.data.groups;
    }
    var clients = []
    if(item.data.clients !== undefined) {
      clients = item.data.clients
    }
    if(item.data.bookable) {
      Firebase.database().ref('/events/'+global.uid).push({
        title: item.data.title,
        notes: desc,
        duration: item.data.duration,
        date: Moment(item.data.date, 'DD/MM/YYYY HH:mm').add((num*item.data.interval), 'days').format('DD/MM/YYYY HH:mm'),
        timestamp: ts,
        attendance: 2,
        clients: clients,
        max: max,
        invitees: invitees,
        groups: groups,
        template: item.id,
        templateSeq: num,
        link: link,
        bookable: true,
        private: false,
      }).then(() => {
        //this.createSessions(item, num+1)
      }).catch((error)=>{
        EventEmitter.dispatch('showMessageError', error.message);
        this.setState({loading: false})
      })
    } else {
      Firebase.database().ref('/events/'+global.uid).push({
        title: item.data.title,
        notes: desc,
        duration: item.data.duration,
        date: Moment(item.data.date, 'DD/MM/YYYY HH:mm').add((num*item.data.interval), 'days').format('DD/MM/YYYY HH:mm'),
        timestamp: ts,
        emails: '',
        template: item.id,
        templateSeq: num,
        link: link,
        private: priv
      }).then(() => {
        //this.createSessions(item, num+1)
      }).catch((error)=>{
        EventEmitter.dispatch('showMessageError', error.message);
        this.setState({loading: false})
      })
    }
  }


  checkSessionsArchive() {
    var locked = false
    if(!global.archiveLocked && global.userRole === '') {
      for(var item of global.sessions) {
        if(Moment(item.data.date, 'DD/MM/YYYY HH:mm') < Moment().add(-60, 'days')) {
          locked = true
          global.archiveLocked = true
          var move = true
          if(item.program[0] !== undefined) {
            if(item.program[0].data.benchmark) {
              move = false
            }
          }
          if(move) {
            Firebase.database().ref('/archive/'+global.uid+'/'+item.id).update(
              item.data
            )
            Firebase.database().ref('/sessions/'+global.uid+'/'+item.id).remove()
          }
        }
      }
      
      setTimeout(() => {
        if(locked) {
          global.archiveLocked = false
          this.loadSessions((result) => {})
          this.loadArchive((result) => {})
        }
      }, 3000);
    }
  }


  checkPaymentsArchive() {
    var locked = false
    if(!global.paymentsLocked && global.userRole === '') {
      for(var item of global.payments) {
        var date = Moment(item.data.date, 'DD/MM/YYYY HH:mm')
        if(item.data.timestamp !== undefined) {
          date = Moment(item.data.timestamp, 'X')
        }
        //if(Moment(item.data.date, 'DD/MM/YYYY HH:mm') < Moment().add(-60, 'days')) {
        if(date < Moment().add(-60, 'days')) {
          locked = true
          global.paymentsLocked = true
          Firebase.database().ref('/paymentsArchive/'+global.uid+'/'+item.id).update(
            item.data
          )
          Firebase.database().ref('/payments/'+global.uid+'/'+item.id).remove()
        }
      }
      
      setTimeout(() => {
        if(locked) {
          global.paymentsLocked = false
          this.loadPayments((result) => {})
        }
      }, 3000);
    }
  }


  checkEventsArchive() {
    var locked = false
    if(!global.eventsLocked && global.userRole === '') {
      for(var item of global.events) {
        if(Moment(item.data.date, 'DD/MM/YYYY HH:mm') < Moment().add(-60, 'days')) {
          locked = true
          global.eventsLocked = true
          var move = true
          if(move) {
            Firebase.database().ref('/eventsArchive/'+global.uid+'/'+item.id).update(
              item.data
            )
            Firebase.database().ref('/events/'+global.uid+'/'+item.id).remove()
          }
        }
      }
      
      setTimeout(() => {
        if(locked) {
          global.archiveLocked = false
          this.loadSessions((result) => {})
          this.loadArchive((result) => {})
        }
      }, 3000);
    }
  }


  checkDuplicates() {
    var toDelete = []
    for(var item of global.recurring) {
      var arr = []
      for(var session of global.sessions) {
        if(session.data.template === item.id) {
          if(arr.indexOf(session.data.templateSeq) === -1) {
            arr.push(session.data.templateSeq)
          } else {
            toDelete.push(session.id)
          }
        }
      }
    }
    this.deleteDuplicates(toDelete)
  }


  deleteDuplicates(arr) {
    for(var item of arr) {
      Firebase.database().ref('/sessions/'+global.uid+'/'+item).remove()
    }
  }


  loadTrainers() {
    let itemsRef = Firebase.database().ref('/users/');
    var tmp2 = []
    itemsRef.on('value', (snap) => {
      // get children as an array
      global.spaces = [];
      snap.forEach((child) => {

        let bRef = Firebase.database().ref('/users/'+child.key+'/assistant/');
        var blocks = []
        bRef.once('value', (bsnap) => {
          bsnap.forEach((bchild) => {
            tmp2.push(child.val().name)
            blocks.push({
              id: bchild.key,
              data: bchild.val(),
            });
          });
        });

        let cRef = Firebase.database().ref('/users/'+child.key+'/cards/');
        var cards = []
        cRef.once('value', (csnap) => {
          csnap.forEach((cchild) => {
            cards.push({
              id: cchild.key,
              data: cchild.val(),
            });
          });
        });

        global.spaces.push({
          id: child.key,
          data: child.val(),
          ass: blocks,
          cards: cards
        });
      });
      EventEmitter.dispatch('trainersLoaded', 'loaded');
    });
  }


  loadPlansClient(callback) {
    let itemsRef = Firebase.database().ref('/plans/'+global.plansUid);
      itemsRef.on('value', (snap) => {
      global.clientPlans = [];
      snap.forEach((child) => {

        let wRef = Firebase.database().ref('/plans/'+global.plansUid+'/'+child.key+'/weeks/');
        var weeks = []
        wRef.once('value', (ssnap) => {
          ssnap.forEach((wchild) => {
            weeks.push({
              id: wchild.key,
              data: wchild.val(),
            });
          });
        });

        let pRef = Firebase.database().ref('/plans/'+global.plansUid+'/'+child.key+'/workouts/');
        var programs = []
        pRef.once('value', (psnap) => {
          psnap.forEach((pchild) => {

            let bRef = Firebase.database().ref('/plans/'+global.plansUid+'/'+child.key+'/workouts/'+pchild.key+'/blocks/');
            var blocks = []
            bRef.once('value', (bsnap) => {
              bsnap.forEach((bchild) => {
                blocks.push({
                  id: bchild.key,
                  data: bchild.val(),
                });
              });
            });

            programs.push({
              id: pchild.key,
              data: pchild.val(),
              blocks: blocks
            });
          });
        });

        if(child.val().uid === global.uid) {
          global.clientPlans.push({
            id: child.key,
            data: child.val(),
            weeks: weeks,
            programs: programs
          });
        }
        
      });
      EventEmitter.dispatch('plansLoaded', 'loaded');
      callback({success: true});
    });
  }


  loadPublic() {
    let itemsRef = Firebase.database().ref('/public/'+global.uid);
    itemsRef.on("value", function(snapshot) {
      global.publicData = []
      if (snapshot.exists()) {
        var data = snapshot.val();
        global.publicData.push({
          status: data.status,
          name: data.name,
          desc: data.desc,
          color: data.color,
          image: data.image,
          video: data.video,
          list: data.blacklist
        });

        let rRef = Firebase.database().ref('/public/'+global.uidUser+'/requests');
          rRef.on('value', (snap) => {
            // get children as an array
            global.publicRequests = [];
            snap.forEach((child) => {
              global.publicRequests.push({
                id: child.key,
                data: child.val(),
              });
            });
          });
      }
      if(global.maxPlans === 0 && global.publicData.length > 0) {
        if(global.publicData[0].status === 'active') {
          Firebase.database().ref('/public/'+global.uid).update({
            status: 'disabled'
          })
        }
      }
      EventEmitter.dispatch('publicRequestsLoaded', 'loaded');
    });    
  }


  loadCommunity(callback) {
		const itemsRef = Firebase.database().ref('/community/'+global.uid);
    itemsRef.on("value", function(snap) {
      global.community = []
      snap.forEach((child) => {

        let cRef = Firebase.database().ref('/community/'+global.uid+'/'+child.key+'/comments/');
        var comms = []
        cRef.on('value', (csnap) => {
          csnap.forEach((cchild) => {
            comms.push({
              id: cchild.key,
              data: cchild.val(),
            });
          });
        });

        global.community.push({
          id: child.key,
          data: child.val(),
          comments: comms
        });
        
      });
      EventEmitter.dispatch('communityLoaded', 'loaded');
      callback({success: true});
    });
	}


  loadCommunityImage(id, image) {
    Firebase.storage().ref().child(image).getDownloadURL().then((url) => {
      global.communityImages.push({
        id: id,
        image: url
      })
      EventEmitter.dispatch('communityImagesLoaded', 'loaded');
    }).catch((error) => {
      // Handle any errors
    })
  }


  loadInvoices(callback) {
    let itemsRef = Firebase.database().ref('/invoices/'+global.uid);
    itemsRef.on('value', (snap) => {
      global.invoices = [];
      snap.forEach((child) => {
        global.invoices.push({
          id: child.key,
          data: child.val()
        });
      });
      EventEmitter.dispatch('invoicesLoaded', 'loaded');
      callback({success: true});
    });
  }


  loadExpenses(callback) {
    let itemsRef = Firebase.database().ref('/expenses/'+global.uid);
    itemsRef.on('value', (snap) => {
      global.expenses = [];
      snap.forEach((child) => {
        global.expenses.push({
          id: child.key,
          data: child.val()
        });
      });
      EventEmitter.dispatch('expensesLoaded', 'loaded');
      callback({success: true});
    });
  }


  loadExpensesCat(callback) {
    let itemsRef = Firebase.database().ref('/expensesCat/'+global.uid);
    itemsRef.on('value', (snap) => {
      global.expensesCat = [];
      for(var item of global.expCats) {
        global.expensesCat.push({
          id: item.id,
          data: {
            name: item.name,
          }
        })
      }
      snap.forEach((child) => {
        global.expensesCat.push({
          id: child.key,
          data: child.val()
        });
      });
      EventEmitter.dispatch('expensesLoaded', 'loaded');
      callback({success: true});
    });
  }


  loadHabits(callback) {
    let itemsRef = Firebase.database().ref('/habits/'+global.uid);
    itemsRef.on('value', (snap) => {
      global.habits = [];
      snap.forEach((child) => {
        global.habits.push({
          id: child.key,
          data: child.val()
        });
      });
      EventEmitter.dispatch('habitsLoaded', 'loaded');
      callback({success: true});
    });
  }


  loadDocuments(callback) {
    let itemsRef = Firebase.database().ref('/documents/'+global.uid);
    itemsRef.on('value', (snap) => {
      global.documents = [];
      snap.forEach((child) => {
        global.documents.push({
          id: child.key,
          data: child.val()
        });
      });
      EventEmitter.dispatch('documentsLoaded', 'loaded');
      callback({success: true});
    });
  }


  loadAdminClients() {
    let itemsRef = Firebase.database().ref('/usersClients/');
    itemsRef.on('value', (snap) => {
      // get children as an array
      global.adminClients = [];
      snap.forEach((child) => {

        let tRef = Firebase.database().ref('/usersClients/'+child.key+'/trainers/');
        var trainers = []
        tRef.once('value', (tsnap) => {
          tsnap.forEach((tchild) => {
            trainers.push({
              id: tchild.key,
              data: tchild.val(),
            });
          });
        });

        global.adminClients.push({
          id: child.key,
          data: child.val(),
          trainers: trainers
        });
      });
      EventEmitter.dispatch('adminClientsLoaded', 'loaded');
    });
  }


  loadClientConnectUser(id) {
    global.userConnectClient = null
    let itemsRef = Firebase.database().ref('/usersClients/'+id);
    itemsRef.on("value", function(snapshot) {
      if (snapshot.exists()) {
        var data = snapshot.val();
        global.userConnectClient = data
      }
      EventEmitter.dispatch('userConnectClientLoaded', 'loaded');
    });
  }


  loadEmailUsage() {
    global.userConnectClient = null
    let itemsRef = Firebase.database().ref('/admin/emails/'+global.uid);
    itemsRef.on("value", function(snapshot) {
      if (snapshot.exists()) {
        var data = snapshot.val();
        global.spaceEmailUsage = data
      }
      EventEmitter.dispatch('emailUsageLoaded', 'loaded');
    });
  }


  checkBadges() {
    if(global.userRole === '' && !global.badgeLocked) {
      var tmp = []
      var c1 = 0
      var c2 = 0
      var c3 = 0
      var c4 = 0
      var c5 = 0
      for(var item1 of global.sessions) {
        c2++
        if(item1.data.attendance !== 2) {
          c1++
          if(item1.program !== undefined) {
            if(item1.program.length > 0) {
              c3++
              c4++
            }
          }
        }
      }
      for(var item2 of global.archive) {
        c2++
        if(item2.program !== undefined) {
            if(item2.program.length > 0) {
              c3++
            }
          }
      }
      global.programs.forEach(() => {
        c4++
      })
      for(var item3 of global.clients) {
        if(item3.data.nutritionId !== undefined && item3.data.nutritionId !== '') {
          c5++
        }
      }

      // Level 1
      if(global.userBadges.indexOf('l1-client') === -1 && global.clients.length > 0) {
        tmp.push('l1-client')
      }
      if(global.userBadges.indexOf('l1-import') === -1 && global.clients.length > 4) {
        tmp.push('l1-import')
      }
      if(global.userBadges.indexOf('l1-session') === -1 && global.sessions.length > 0) {
        tmp.push('l1-session')
      }
      if(global.userBadges.indexOf('l1-program') === -1 && global.programs.length > 0) {
        tmp.push('l1-program')
      }
      // Level 2
      if(global.userBadges.indexOf('l2-billing') === -1 && global.userStripeConnect !== '' && global.userStripeConnect !== undefined) {
        tmp.push('l2-billing')
      }
      if(c1 > 1 && global.userBadges.indexOf('l2-attendance') === -1) {
        tmp.push('l2-attendance')
      }
      if(global.userBadges.indexOf('l2-product') === -1 && global.products.length > 0) {
        tmp.push('l2-product')
      }
      if(global.userBadges.indexOf('l2-payment') === -1 && global.payments.length > 0) {
        tmp.push('l2-payment')
      }
      if(global.userBadges.indexOf('l1-portal') === -1) {
        tmp.push('l1-portal')
      }
      if(global.userBadges.indexOf('l2-nutrition') === -1 && global.userNutritionId !== '' && global.userNutritionId !== undefined) {
        tmp.push('l2-nutrition')
      }
      // Level 3
      var ex = false
      for(var item4 of global.exercises) {
        if(item4.data.user === global.uidUser) {
          ex = true
        }
      }
      if(ex && global.userBadges.indexOf('l3-exercise') === -1) {
        tmp.push('l3-exercise')
      }
      if(c2 > 100 && global.userBadges.indexOf('l3-sessions') === -1) {
        tmp.push('l3-sessions')
      }
      if(global.userBadges.indexOf('l3-plan') === -1 && global.plans.length > 0) {
        tmp.push('l3-plan')
      }
      if(global.userBadges.indexOf('l3-programs') === -1 && c4 > 10) {
        tmp.push('l3-programs')
      }
      if(c3 > 20 && global.userBadges.indexOf('l3-run') === -1) {
        tmp.push('l3-run')
      }
      if(c5 > 10 && global.userBadges.indexOf('l3-nutrition') === -1) {
        tmp.push('l3-nutrition')
      }
      // Level 4
      if(c2 > 500 && global.userBadges.indexOf('l4-sessions') === -1) {
        tmp.push('l4-sessions')
      }
      if(global.payments.length > 500 && global.userBadges.indexOf('l4-payments') === -1) {
        tmp.push('l4-payments')
      }
      if(c4 > 60 && global.userBadges.indexOf('l4-programs') === -1) {
        tmp.push('l4-programs')
      }
      if(global.plans.length > 20 && global.userBadges.indexOf('l4-plans') === -1) {
        tmp.push('l4-plans')
      }
      if(c3 > 200 && global.userBadges.indexOf('l4-run') === -1) {
        tmp.push('l4-run')
      }
      if(global.publicData.length > 0 && global.publicData !== undefined) {
        if(global.publicData[0].status === 'active' && global.userBadges.indexOf('l4-public') === -1) {
          tmp.push('l4-public')
        }
      }

      if(tmp.length > 0 && !global.badgeLocked && global.uidUser !== '') {
        for(var item of tmp) {
          global.userBadges.push(item)
        }
        Firebase.database().ref('/users/'+global.uidUser).update({
          badges: global.userBadges
        })
      }
    }
    
  }


  sendPushNotification(token, title, body, type, iid, tokens) {
    if(tokens === undefined) {
      var callFunction = Firebase.functions().httpsCallable('sendPush');
      callFunction({token: token, title: title, body: body, type: type, space: global.uid, id: iid}).then(function(result) {});
    } else {
      var callFunction2 = Firebase.functions().httpsCallable('sendPushMultiple');
      callFunction2({tokens: tokens, title: title, body: body, type: type, space: global.uid, id: iid}).then(function(result) {});
    }
    
  }


  getClientToken(id) {
    global.clientToken = ''
    let itemsRef = Firebase.database().ref('/usersClients/'+id);
    itemsRef.on("value", function(snapshot) {
      if (snapshot.exists()) {
        var data = snapshot.val();
        global.clientToken = data.pushToken
      }
      EventEmitter.dispatch('tokenLoaded', 'loaded');
    });
  }


  getClientTokenAll(id, client) {
    let itemsRef = Firebase.database().ref('/usersClients/'+id);
    itemsRef.on("value", function(snapshot) {
      if (snapshot.exists()) {
        var data = snapshot.val();
        var add = true
        for(var tk of global.clientTokens) {
          if(tk.id === id) {
            add = false
          }
        }
        if(add) {
          global.clientTokens.push({
            id: id,
            client: client,
            token: data.pushToken
          })
        }
        
      }
      EventEmitter.dispatch('tokenLoaded', 'loaded');
    });
  }


  getTrainerSelected(id) {
    global.trainerSelected = null
    var c1 = 0
    var c2 = 0
    var c3 = 0
    var c4 = 0
    var c5 = []
    global.trainerSelected = ({
      clients: c1,
      sessions: c2,
      group: c3,
      programs: c4,
      active: c5
    })
    let itemsRef1 = Firebase.database().ref('/clients/'+id);
    itemsRef1.on('value', (snap1) => {
      // get children as an array
      snap1.forEach((child1) => {
        c1++
        if(child1.val().active === undefined) {
          c5.push(child1.val().uid)
        } else {
          if(child1.val().active) {
            c5.push(child1.val().uid)
          }
        }
      });
      global.trainerSelected = ({
        clients: c1,
        sessions: c2,
        group: c3,
        programs: c4,
        active: c5
      })
      EventEmitter.dispatch('trainerSelectedLoaded', 'loaded');
    });
  }


  loadExercisesAdmin() {
		let itemsRef = Firebase.database().ref('/exercises/');
		itemsRef.on('value', (snap) => {
			global.exercises = [];
			snap.forEach((child) => {
        global.exercises.push({
          id: child.key,
          data: child.val(),
        });
			});
			
			EventEmitter.dispatch('exercisesLoaded', 'loaded');
		});
	}


  loadSubscriptions(callback) {
    const itemsRef = Firebase.database().ref('/admin/subs/');
    global.allSubs = []
    itemsRef.on('value', (snap) => {
      snap.forEach((child) => {
        global.allSubs.push({
          id: child.key,
          email: child.val().email,
          stripe: child.val().stripe,
        });
      })
      EventEmitter.dispatch('allSubsLoaded', 'loaded');
      callback({success: true});
    });
  }


  initIntercom() {
    window.Intercom("boot", {
      app_id: "gwe4xp9u",
      name: global.userName, // Full name
      email: global.userEmail, // Email address
      phone: global.userPhone,
      user_id: global.uidUser,
      created_at: Moment(global.userImageDate, 'DD/MM/YYYY HH:mm').format('X'),
      "Plan": global.userSubPlan
    });
    window.Intercom("update");
  }


  // Nutrition Stuff

  createApolloClient = () => {
    return new ApolloClient({
      link: new HttpLink({
        uri: 'https://ptmate-client.hasura.app/v1/graphql',
        headers: {
          'x-hasura-admin-secret': 'Ib2gLkOdCmhuemuKrYTkoPsCZbZIwn0FKfUmwV1OE0TZtdZt1qvMx440gMqM4aQ7',
        }
      }),
      cache: new InMemoryCache(),
    });
  };


}

//QkjKFoXfTT-byFAi5s3yAg
//uU5wmOb1zqNKXABtzQIDNq80i3iIlhCh8QSm - API Sectre
//eyJhbGciOiJIUzI1NiJ9.eyJpc3MiOiI4b2lYUHNBaFJFcTVCT2poMkxhVDF3In0.E0ce6_C3rF7E6DyagQl9rih25dEY2pRUqUKUOxRm5jQ - Chat history


const b = new Connector();
export default b;